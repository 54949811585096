/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import React, { useEffect, Suspense, useRef, useState } from "react";
import ScrollToTop from "../helpers/scroll-top";
import history from "../lib/history";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import Message from "../components/message/Message";
import ProgressDialog from "../components/progressDialog/ProgressDialog";
import { connect, useSelector, useDispatch } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import Pages from "./Pages";
import * as ActionMenu from "../redux/actions/home/menuActions";
import useSocket from "../hooks/useSocket";
import UpdateDataUser from "../pages/home/components/UpdateDataUser";
import useSessionTimer from "../hooks/useSessionTimer";
import FormDatosFacturacion from "../pages/miProducto/components/RealizarPago/FormDatosFacturacion";
import NotificacionesPendientes from "../components/notificacion/NotificacionesPendientes";
import config from "../config/config.json";
import useCalcularRecuperacion from "../hooks/useCalcularRecuperacion";

const RoutesApp = (props) => {
  const dispatch = useDispatch();
  const routerRef = useRef(null);
  const socket = useSocket({ routerRef });

  const [datosActualizados, setDatosActualizados] = useState(false);
  const { getVerificarUsuario } = useCalcularRecuperacion()

  useEffect(() => {
    props.dispatch(
      loadLanguages({
        languages: {
          en: require("../translations/english.json"),
          fn: require("../translations/french.json"),
          de: require("../translations/germany.json"),
        },
      })
    );
    dispatch(ActionMenu.loadingMenu());
  }, []);

  const { load, userToken, ci } = useSelector((stateSelector) => {
    return stateSelector.load;
  });

  useEffect(() => {
    if (userToken) {
      getVerificarUsuario();
    }
  }, [userToken]);

  return (
    <ToastProvider placement="bottom-left">
      <Message />
      <ProgressDialog />
      <BreadcrumbsProvider>
        <Router ref={routerRef} history={history}>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              {!userToken ? (
                <Switch>
                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/"}
                    component={Pages.Login}
                  />
                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/recuperar-pass"}
                    component={Pages.RecuperarPass}
                  />
                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/cambiar-pass/:usuario"}
                    component={Pages.CambiarPass}
                  />
                  {window.ReactNativeWebView && (
                    <Route
                      exact
                      path={
                        process.env.PUBLIC_URL +
                        "/mobile/:tipodispositivo/:mac/:token"
                      }
                      component={Pages.Mobile}
                    />
                  )}
                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/maintenance"}
                    component={Pages.Maintenance}
                  />
                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/atencion-cliente"}
                    component={Pages.AtencionCliente}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/verificar-pago/:tipopago"}
                    component={Pages.VerificarPagoFacilPage}
                  />
                  <Route exact component={Pages.NoFoundExt} />
                </Switch>
              ) : (
                <Switch>
                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/"}
                    component={Pages.Home}
                  />
                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/saldo-cuenta"}
                    component={Pages.SaldoCuenta}
                  />
                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/mi-producto"}
                    component={Pages.Miproductos}
                  />
                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/mejorar-membresia"}
                    component={Pages.MejorarMembresia}
                  />

                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/detalle-productos"}
                    component={Pages.Detalleproductos}
                  />
                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/detalle-productos-neizan"}
                    component={Pages.DetalleproductosNeizan}
                  />

                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/realizar-pago"}
                    component={Pages.RealizarPago}
                  />
                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/realizar-pago-neizan"}
                    component={Pages.RealizarPagoNeizan}
                  />
                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/generar-contrato"}
                    component={Pages.GenerarContrato}
                  />
                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/Producto/Vendido"}
                    component={Pages.ProductoVendido}
                  />
                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/red/clientes"}
                    component={Pages.Arbol}
                  />
                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/notificationes"}
                    component={Pages.Notificacion}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/mapa/proyecto/:idproyecto"}
                    component={Pages.Mapa}
                  />
                  <Route
                    path={
                      process.env.PUBLIC_URL +
                      "/mapa/proyecto_caracteristicas/:idproyecto"
                    }
                    component={Pages.MapaCaracteristicas}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/verificar-pago/:tipopago"}
                    component={Pages.VerificarPagoFacilPage}
                  />
                  {/*<Route
                    path={process.env.PUBLIC_URL + "/membresias"}
                    component={Pages.Membresias}
                  />
                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/proyectos"}
                    component={Pages.Proyectos}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/forma-pago/lote/:idlote"}
                    component={Pages.PlanPagosProyectos}
                  />
                  <Route
                    path={
                      process.env.PUBLIC_URL +
                      "/detalle/proyecto/:idproyecto/:idempresa"
                    }
                    component={Pages.ListaProductoProyecto}
                  />
                  <Route
                    path={
                      process.env.PUBLIC_URL +
                      "/detalle/proyecto-caracteristicas/:idproyecto"
                    }
                    component={Pages.ListaProductosProyectosCaracteristicas}
                  />*/}
                  <Route
                    path={process.env.PUBLIC_URL + "/usuario"}
                    component={Pages.Usuario}
                  />
                  {/*<Route
                    path={process.env.PUBLIC_URL + "/formprereserva"}
                    component={Pages.FormPrereserva}
                  />
                  */}
                  <Route
                    path={process.env.PUBLIC_URL + "/prereserva-membresia"}
                    component={Pages.FormPrereservaMembresia}
                  />
                  {/*<Route
                    path={process.env.PUBLIC_URL + "/reconfigurar-plan-pago"}
                    component={Pages.ReconfigurarPlanPago}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/imprimir-documentos"}
                    component={Pages.ImprimirDocumentos}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/tarjetas-prepago"}
                    component={Pages.TarjetaPrepago}
                  />*/}
                  <Route
                    path={process.env.PUBLIC_URL + "/cambiar-pass"}
                    exact
                    component={Pages.CambiarContrasenha}
                  />
                  {/*<Route
                    path={process.env.PUBLIC_URL + "/ranking"}
                    exact
                    component={Pages.RankingBonoViaje}
                  />*/}
                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/maintenance"}
                    component={Pages.Maintenance}
                  />
                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/capacitacion"}
                    component={Pages.Capacitacion}
                  />

                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/eventos"}
                    component={Pages.Eventos}
                  />


                  <Route
                    exact
                    path={process.env.PUBLIC_URL + '/eventos-aci'}
                    component={Pages.EventosACI}
                  />

                  <Route
                    exact
                    path={process.env.PUBLIC_URL + '/eventos-aci/crear'}
                    component={Pages.EventosACICrear}
                  />

                  <Route
                    exact
                    path={process.env.PUBLIC_URL + '/eventos-aci/historial/sala'}
                    component={Pages.EventosACIHistorialSala}
                  />

                  <Route
                    exact
                    path={process.env.PUBLIC_URL + '/eventos-aci/historial/invitados'}
                    component={Pages.EventosACIHistorialInvitados}
                  />

                  {/* <Route
                    exact
                    path={process.env.PUBLIC_URL + '/eventos-aci/unirse'}
                    component={Pages.EventosACIUnirse}
                  /> */}

                  <Route
                    exact
                    path={process.env.PUBLIC_URL + '/eventos-aci/sala/:id'}
                    component={Pages.EventosACISala}
                  />

                  <Route
                    exact
                    path={process.env.PUBLIC_URL + '/eventos-aci/sala/:id/invitar'}
                    component={Pages.EventosACIInvitar}
                  />


                  <Route
                    exact
                    path={process.env.PUBLIC_URL + '/eventos-aci/sala/:id/invitacion/:idinvitacion'}
                    component={Pages.EventosACIInvitarModificar}
                  />

                  <Route
                    exact
                    path={process.env.PUBLIC_URL + '/eventos-aci/sala/:id'}
                    component={Pages.EventosACISala}
                  />
                  <Route
                    exact
                    path={process.env.PUBLIC_URL + '/eventos-aci/sala/:id/administrar'}
                    component={Pages.EventosACIAdministrar}
                  />

                  <Route
                    exact
                    path={process.env.PUBLIC_URL + '/eventos-aci/sala/:id/controlasistencia'}
                    component={Pages.EventosACIControlAsistencia}
                  />


                  <Route
                    exact
                    path={
                      process.env.PUBLIC_URL + "/cambiar-pass-segura/:usuario"
                    }
                    component={Pages.CambiarPass}
                  />
                  {config.HABILITAR_MEMBRESIA_INTERNACIONAL && [
                    <Route
                      exact
                      path={process.env.PUBLIC_URL + "/membresia-internacional"}
                      component={Pages.MembresiaInternacional}
                    />,
                    <Route
                      exact
                      path={process.env.PUBLIC_URL + "/upgrade-membresia"}
                      component={Pages.UpgradeInternacional}
                    />,
                    <Route
                      exact
                      path={process.env.PUBLIC_URL + "/beneficios-travel"}
                      component={Pages.ActivarBeneficio}
                    />,
                    <Route
                      exact
                      path={process.env.PUBLIC_URL + "/beneficios-travel/pagar"}
                      component={Pages.PagarBeneficio}
                    />,
                  ]}

                  <Route
                    exact
                    path={
                      process.env.PUBLIC_URL + "/prereservar-abono/:tipoAbono"
                    }
                    component={Pages.PrereservarAbono}
                  />
                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/tramites"}
                    component={Pages.Tramites}
                  />
                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/reservar-cabana"}
                    component={Pages.MembresiasCabana}
                  />
                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/sion-hotel/:idMembresia"}
                    component={Pages.Hotel}
                  />
                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/carnet-Kalomai"}
                    component={Pages.CarnetKalomai}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/bono-mejor-vendedor"}
                    exact
                    component={Pages.bonoMejorVendedor}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + "/calcular-recuperacion-producto"}
                    exact
                    component={Pages.CalcularParaRecuperar}
                  />

                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/promocion-dos-por-uno"}
                    component={Pages.Promocion2X1Septiembre}
                  />

                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/tickets"}
                    component={Pages.Tickets}
                  />
                  <Route exact
                    path={process.env.PUBLIC_URL + '/eventos-aci/sala/:id/administrar/presentadores'}
                    component={Pages.EventosACIModificarPresentadores}
                  ></Route>
                  <Route component={Pages.NotFound} />
                </Switch>
              )}
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
      {userToken && (
        <>
          <UpdateDataUser setDatosActualizados={setDatosActualizados} />
          {datosActualizados && (
            <FormDatosFacturacion botonFormDatosFactura={false} />
          )}
          <NotificacionesPendientes />
        </>
      )}
    </ToastProvider>
  );
};

RoutesApp.propTypes = {
  dispatch: PropTypes.func,
};

export default connect()(multilanguage(RoutesApp));
