export default {
    homePage: {
        eventos: "Events",
        capacitacion: "Training",
        ranking: "Ranking",
        verImagen: "View image",
        verMas: "SEE MORE",
        verMenos: "SEE LESS",
        espanhol: "Spanish",
        ingles: "English",
        portugues: "Portuguese",
        principal: "Home",
        terrenos: "Land",
        membresias: "Memberships",
        casas: "Houses",
        nuestrosProductos: "Our Products",
        meInteresa: "I'M INTERESTED",
        novedades: "News",
        terrenosText:
            "Obtain your land, located in the best area of your city, think about your future and that of your loved ones, think big, because if I can ... you can!",
        membresiasText:
            'Now you can get your membership for "KALOMAI PARK", the best water park in Bolivia located in the department of Santa Cruz de la Sierra.',
        casasText:
            "Get your new house, with financing and at an incredible price in the best urban projects located in our city of Santa Cruz de la Sierra.",
        abonosText:
            "Follow the ROYAL PARI team buying your subscription for an entire management. Be part of this soccer passion.",
        ultimasNoticias: "LAST NEWS",
        nuestrasApps: "OUR APPS",
        empresas: "COMPANIES",
        empresasSocias: "PARTNER COMPANIES",
        direccion: "Address Av. Cañoto N673",
        telefono: "Phone Number",
        oficinaVirtual: "Virtual Office",
        siguenos: "FOLLOW US",
        pagar: "My products",
        productos: "PRODUCTS",
        perfil: "PROFILE",
        productosVendidos: "Sold Products",
        liberarReservas: "Release Reserves",
        tarjetasPrepago: "Prepaid Cards",
        proyectosCasas: "House Projects",
        antiguoPortal: "Old Portal",
        cerrarSesion: "Log Out",
        cambiarPassword: "Change Password",
        verCasas: "See Houses",
        verTerrenos: "See Land",
        verMembresias: "See Memberships",
        abonosRP: "Royal Pari Membership",
        reservasCabana: "Book a hut",
        tramite: "Formalities",
        idioma: "English",
        sesionInactividadTitulo: "Session expired",
        sesionInactividadMensaje: "Your session expired due to inactivity.",
        sessionInactividadBoton: "OK",
        miCarnetkalomai: "Kalomai Accreditation",
        descargarCarnet: "Download",
        redClientes: "Clients Network",
        bonoMejoresVendedores: "Best seller bonus",
        membresiaInternacional: "International Membership",
        botonPagar: "Pay",
        eventosACI: "Meeting room"
    },
    proyectosPage: {
        adquiereTuTerreno: "ACQUIRE YOUR LAND",
        verLista: "See in list",
        verPlano: "See Plan",
        verMapa: "See Map",
        verEnMapa: "See in Map",
        filtros: "Filters",
        filtrosBusqueda: "Search Filters",
        manzano: "Block",
        lote: "Land",
        producto: "Product",
        precio: "Price",
        desc: "Disc",
        precioDesc: "Price Disc",
        inicial: "Initial",
        mensual: "Monthly",
        prereservas: "Pre-reservations",
        prereservar: "Pre-reserve",
        orden: "Order",
        plazo: "Term",
        estado: "Status",
        superficie: "Surface",
        buscar: "Search",
        seleccione: "Select",
        precioMasBajo: "Lowest Price",
        precioMasAlto: "Highest price",
        terrenosPequenhos: "Smaller plots",
        terrenosGrandes: "Bigger land",
        oro: "Gold",
        contado: "Counted",
        contadoPorcentage: "Counted {{porcentaje}}% discount",
        inicialDescuento: "3 years 20% discount {{inicial}}% initial fee",
        todos: "All",
        terrenosPrereserva: "Prereserved land",
        terrenosLibres: "Free Land",
        filtrar: "Filter",
        nroManzano: "Block N",
        codigoProducto: "PRODUCT CODE",
        formasCompra: "Forms of Purchase",
        verPrereservas: "See Pre-reservations",
        nroPrereserva: "Pre-reservation number",
        fechaRegistro: "Regsitration date",
        resultadoFiltroCaracteristicas:
            "found {{encontrados}} of {{total}} vacant lots",
        resultadoFiltroComplejos: "{{total}} vacant lots",
        nroReserva: "Reservation number",
        nroVenta: "Sale number",
    },
    membresiasPage: {
        contado: "Counted",
        credito: "Credit",
        descuento: "Discount",
        precioCDescuento: "Price C/ Discount",
        porcentajeInicial: "Initial Percentage",
        plazo: "Term",
        cuotaInicial: "Initial fee",
        cuotaMensual: "Monthly fee",
        simularContrato: "Simulate Contract",
        simularPlan: "Simulate Payment Plan",
        reservar: "Reserve",
        vista360: "View 360",
        anterior: "Previous",
        simulacionContrato: "Contract simulation",
        simulacionPlanPago: "Payment plan simulation",
        aceptar: "Accept",
        coloqueCi: "Place your identity card",
        ahorro: "Saving",
        precioAhora: "Now",
        precioAntes: "Before",
        meses: "Months",
        montoInicialInvalido: "You must enter a valid value to continue",
        montoInicialMayorValidacion:
            "The amount deposited cannot be greater than or equal to the amount of the proceeds of $us ",
        montoInicialMenorValidacion:
            "The amount deposited cannot be less than or equal to the down payment of $us ",
        mensajeTooltipInicial:
            "Now you can edit the amount of your down payment, it must be greater than 10% of the value of the product.",
        subtitulodiagonalTarjeta: "Offer",
        nuevaInicial: "New initial",
        saldoAFinanciar: "Balance to finance",
        restriccionesAplican: "Restrictions apply",
        nuevoInteres: "Interest for new sales from {{fecha}}",
        nuevaCuotaMensual: "New monthly fee",
        errorMora: 'The client with CI {{ci}} has quotas in arrears, only clients who are up to date are allowed to make reservations..',
        alContado: "Cash",
        alertDescuento: "For being a member of Grupo Sion you have an additional discount.",
        sinConfiguracion: "No configuration found",
        primeraCuota: "First installment",
        promocion2x1: "Promocion 2 x 1"
    },
    casasPage: {
        adquiereTuCasa: "Acquire your house",
        codigoProducto: "Code product",
        interesados: "Interested",
        precioTotal: "Total Price",
        verFicha: "See File",
        seleccionar: "Select",
        tituloModelosCasas:
            "In the block {{nroManzano}} lot {{nroLote}} the following house models can be built",
        informacion: "INFORMATION",
        caracteristicas: "Features",
        planDePagos: "PAYMENT PLAN",
        detalleCuotaInicial: "Detail of the initial fee",
        desgloseCuotaInicial: " Breakdown of 3% of the initial installment",
        saldoPagar: "Balance to be paid in 7 days",
        financiamientoDirecto: "Direct financing of 7%",
        cuotasMensuales: "5 monthly installments of",
        detalleFinanciamiento: "Details of the financing",
        totalVivienda: "Total Housing",
        totalAFinanciar: "TOTAL TO FINANCE",
        cuotaMensualAproximada: "Approximate monthly payment",
        nota: "Note",
        mensajeNota:
            "The data of the initial payment and monthly payments are referential and are subject to modifications according to bank analysis",
        simulacionPlanPagos: "Plan simulation of payments",
        montoFinanciar: "Amount to be financed",
        monto: "Amount",
        continuar: "Continue",
    },
    pagarPage: {
        prereserva: "Pre-reserve",
        reserva: "Reservation",
        compra: "Purchase",
        totalPrecioProducto: "Total price of the product",
        cuotaMensual: "Monthly payment",
        expensa: "Expense",
        pagadoACuenta: "Paid on account",
        pagarSaldoInicial: "Pay the initial payment balance with",
        validez: "Validity",
        mesesTranscurridos: "Elapsed months",
        reconfigurarPlanPagos: "Reconfigure payment plan",
        historicoPagos: "Payment History",
        comprobante: "Voucher",
        nombreProducto: "Product Name",
        codigoProducto: "Product Code",
        nroVenta: "No. Sale",
        nroRecibo: "Receipt No.",
        interes: "Interest",
        fechaPago: "Payment Date",
        aCuenta: "On account",
        amortizado: "Amortized",
        multa: "Fine",
        totalPagado: "Total Paid",
        fecha: "Date",
        pagaTuReservaCon: "Pay your reservation with",
        terreno: "Land",
        casa: "House",
        compras: "Purchases",
        totalDeuda: "Total Debt",
        cuotasVencidas: "Overdue Fees",
        saldoCuotaInicial: "Initial Fee Balance",
        financiamientoDirecto: "Direct Financing",
        saldoFinanciar: "Balance to be financed",
        totalCuotaInicial: "Total Initial Fee",
        fechaLimite: "Deadline",
        montoRestanteInicial: "Remaining Amount To Pay Initial",
        fechaContrato: "Contract Date",
        mejorar: "Upgrade",
        mensajePrereservaAbono:
            "To continue, you must make the payment of your subscription by box or by bank deposit in accounts of the Royal Pari club.",
        mensajeLimitePagoTarjeta:
            "Due to banking restrictions, it is not allowed to make payments with amounts greater than {{monto}} $us.",
        pagoRestringido: "Due to maintenance, payments can be made from {{fecha}}",
        certificadoCancelacionTotal: "Total Cancellation Certificate",
    },
    loginPage: {
        iniciarSesion: "Login",
        usuario: "User",
        password: "Password",
        ingresar: "Enter",
        olvideMiPassword: "I forgot my password",
        verificarCi: "VERIFY CI",
        metodoRecuperacion: "RECOVERY METHOD",
        finalizar: "FINALIZE",
        carnetIdentidad: "Identity Card",
        siguiente: "Next",
        enviarCodigo: "Send Code",
        enviarCodigoVerificacion: "Send Verification Code to:",
        mensajeCodigoEnviado:
            "A verification code has been sent to the selected option.",
        verificar: "Verify",
        atencion: "Attention",
        mensajeSinCorreo:
            "You do not have an email address, please update your information by contacting customer service.",
        mensajeCorreoNoValido:
            "The selected email does not appear to be valid, please update your information by contacting customer service or select another password recovery option.",
        atencionCliente: "Customer service",
        cerrar: "Close",
        ingresarNuevoPassword: "ENTER YOUR NEW PASSWORD",
        mensajeReglasPassword:
            "The password must meet the following characteristics.",
        caracteresMinimos: "Minimum 8 characters",
        almenosMayusculaMinuscula: "At least one upper and lower case",
        caracterEspecial: "One special character",
        confirmarPassword: "Password must be equal to the Confirm Password",
        errorPassword: "The password does not meet the criteria described above",
        tituloNuevoPassword: " NEW PASSWORD",
        confirmarPasswordLabel: "Confirm Password",
        mensajeCambioPassword:
            "The new password was updated successfully. Please log in with your new credentials.",
        deAcuerdo: "OK",
        ingresarCodigoVerificacion: "Enter Verification Code",
        mensajeUsuarioNuevo:
            "In order to take care of your information you must create a secure PASSWORD. A verification code will be sent.",
        ingresarAntiguoPortal: "Go to the old portal",
        clickAqui: "Click here",
        problemasIngresarPortal:
            "If you have any problems you can return to the Old Portal.",
        contactanos: "Contact us",
        modalContactoTitulo: "We are here to help you",
        modalContactoContenido:
            "Contact customer service so you can make your questions.",
        modalReintentoTitulo: "Attention",
        modalReintentoContenido:
            "If you do not remember your password, we recommend using the option:",
        inicioDeSesion: "Login with",
        huella: "fingerprint",
        preguntaActivarHuella:
            "Do you want to activate the login with fingerprint in your account?",
        preguntaActivarFaceId:
            "Do you want to activate the login with Face ID in your account?",
        subituloModalHuella:
            "Remember that you can activate or deactivate this option from your user profile.",
        activar: "Activate",
        modalCarnet:
            "Dear customer, you are advised to set a secure access password. You have a period of {{dias}} days to perform this action.",
        modalCarnetUnDia:
            "Dear customer, you are advised to set a secure access password. You have a period of {{dias}} day to perform this action.",
        nuestraApp: "OUR APP",
        textoCodigoEtica: "I accept the code of ethics",
        verCodigoEtica: "See",
        errorCodigoEtica: "You must accept the code of ethics.",
    },
    notificacionPage: {
        notificaciones: "Notifications",
        cargarMas: "Load more",
        tituloPaginaNotificacion:
            "Here you can see the messages that Grupo Sion has sent to you",
        sinNotificaciones: "No notifications",
        marcarLeido: "Mark Read",
        verMas: "See more",
        cargando: "Loading",
    },
    perfilPage: {
        nombreCompleto: "Full Name",
        ci: "CI",
        telefono: "Telephone",
        correo: "Email",
        celular: "Cellphone",
        direccion: "Address",
        mensajeInformacionPerfil:
            "Any data change in your profile information, please contact",
        calificacion: "Qualification: ",
    },
    tarjetaPrepagoPage: {
        campoRequerido: "This field is required",
        textoCaptcha: "The text entered does not match the captcha",
        tituloPagina:
            "Enter the code of your card to check your available balance.",
        mensaje: "Message",
        saldo: "Balance",
        consultarSaldo: "Check Balance",
        codigoTarjeta: "Card Code",
        codigoCaptcha: "Captcha Code",
    },
    productosVendidosPage: {
        producto: "Product",
        cliente: "Client",
        telefono: "Phone",
        email: "Email",
        fecha: "Date",
        estado: "Status",
        comisionable: "Commissionable",
        totalVenta: "Total Sale",
        venta: "Sale",
        prereserva: "PRE-RESERVE",
        reserva: "RESERVE",
        manzano: "Apple tree",
        lote: "Lot",
        superficie: "Surface",
        precio: "Price",
        descuento: "Discount",
        totalPrecioProducto: "Total product price",
        cuotaInicial: "Initial Fee",
        cuotaMensual: "Monthly Fee",
        expensa: "Expense",
        pagado: "Paid",
        saldo: "Balance",
        fechaVencimiento: "Expiration Date",
        nombre: "Name",
        ci: "CI",
        liberarPrereserva: "Release Pre-reservation",
        prereservaLiberada: "Preserve Released",
        seLiberoPrereserva: "The pre-reservation was released correctly.",
        motivo: "Reason",
        campoRequerido: "This field is required",
        cerrar: "Close",
        cancelar: "Cancel",
        liberar: "Release",
        ventasVacio: "At the moment, you have not made any reservations.",
        todos: "All",
        soloDeudores: "Debtors",
        soloNoDeudores: "Non-debtors",
    },
    detallePagoPage: {
        errorMontoValido: "You must enter a valid amount to continue.",
        errorMontoCero: "The amount to be paid cannot be zero.",
        errorMontoIncorrecto: "The amount to be paid is incorrect.",
        errorMontoMayorDeuda:
            "The amount to be paid cannot be greater than the total debt.",
        errorFormatoMonto: "The format of the amount to pay is incorrect.",
        mensajeZurielDeshabilitado:
            "We are working to provide you with the best services, new features will be available very soon.",
        detalleProductos: "Product Details",
        resumenPago: "PAYMENT SUMMARY",
        agregarCuotas: "ADD FEES",
        detallePago: "PAYMENT DETAIL",
        pagar: "PAY",
        generarContrato: "GENERATE CONTRACT",
        seleccionarCuotas: "Select the installments to add",
        cuotaVenceFecha: "Fee #{{nroCuota}}, EXPIRES DATE:",
        cancelar: "Cancel",
        agregar: "Add",
        precio: "Price",
        pago: "Payment",
        debe: "Debit",
        cuotasPendientes: "Pending Installments",
        vencidas: "Due",
        mensual: "Monthly",
        plazo: "Term",
        meses: "months",
        fechaVencimiento: "Due Date",
        ingreseMontoPagar: "Enter the amount to pay",
        inicial: "INITIAL",
        cuotaInicial: "Initial Fee",
        subTotal: "SUBTOTAL",
        pagosACuenta: "PAYMENTS TO ACCOUNT",
        totalPagar: "TOTAL TO PAY",
        pagado: "PAID",
        detalleCuotas: "Detail of Installments",
        nro: "No.",
        vence: "Due",
        capital: "Capital",
        interes: "Interest",
        formulario: "Form",
        expensas: "Expenses",
        multas: "Fines",
        importeCuota: "Fee Amount",
        pagoCuenta: "Payment on account",
        total: "Total",
        nroCuota: "Nro. Share",
        detalles: "Details",
        contado: "Counted",
    },
    realizarPagoPage: {
        mensaje3ds:
            "Dear customer, due to security issues it is possible that a transaction is made to validate your card, this action will not incur any cost.",
        mensajePago: "Pay with your points directly from the Sion Pay application.",
        realizarPago: "Make Payment",
        pagoCuotas: "Payment of Fees",
        pagoPrereserva: "Payment of Pre-reservation",
        pagoReserva: "Payment of Reservation",
        pagoProcesado: "Payment processed correctly",
        pagoExitosoPrereserva:
            "You have just made the payment of your pre-reservation correctly",
        pagoExitosoCuotas: "You have just made the payment of your fees correctly",
        pagoExitosoCuota: "You have just made payment of your fees correctly",
        pagoExitosoReserva:
            "You have just made the payment of your reservation correctly",
        volver: "Back",
        terminosCondiciones: "TERMS AND CONDITIONS",
        metodoPago: "PAYMENT METHOD",
        datosPago: "PAYMENT INFORMATION",
        finalizar: "FINISH",
        codigoTarjetaRequerido: "The Code field is required",
        codigoTarjetaInvalido: "The entered code is invalid",
        tarjetaPrepago: "Prepaid Card",
        proyecto: "Project",
        producto: "Product",
        montoPagar: "Amount to Pay",
        indicaciones: "Directions",
        indicacionCompra:
            "Please enter the codes one by one, pressing Load until you reach the balance to pay.",
        indicacionPrereservaReserva:
            "Please enter the code of your card and press Process to continue.",
        cargar: "Load",
        codigo: "Code",
        codigoTarjeta: "Card Code",
        montoTarjeta: "Card Amount",
        montoAplicar: "Apply Amount",
        verCuotas: "See Fees",
        atras: "Back",
        procesar: "Process",
        errorTarjetaRequerida: "The Card field is required",
        errorVisaMastercard: "Only Visa or MasterCard cards are allowed",
        errorTarjetaIncompleta: "You must complete the card number to continue",
        errorTarjetaIncorrecta: "You must complete the Card field correctly",
        errorExpiracionRequerido: "The Expiration field is required",
        errorExpiracionIncorrecto:
            "You must complete the Expiration field correctly",
        errorMesExpiracion:
            " The Month entered in the Expiration field is incorrect",
        errorAnhioExpiracion:
            "The Year entered in the Expiration field is incorrect",
        errorMesAnhioExpiracion:
            "The Month and Year entered in the Expiration field is incorrect",
        errorCvcRequerido: "The CVC field is required",
        errorCvcIncorrecto: "You must complete the CVC field correctly",
        numeroTarjeta: "Card number",
        expiracion: "Expiration",
        cuentaSionPayNoCreada: "Your SION Pay account has not been created.",
        pagoSionPay: "Payment with SionPay",
        cuenta: "Account",
        estadoCuenta: "Account Status",
        puntosDisponibles: "Available Points",
        descuento: "Discount",
        totalPagar: "Total to Pay",
        ingresarCodigo: "Enter your PIN code generated from your Sion Pay account",
        codigoPin: "PIN code",
        tituloPagoSionPay: "Payment Information - Payments with SionPay points",
        solicitarPagoAmigo: "Ask a friend to pay the fee",
        generarCodigo: "GENERATE CODE",
        generarCodigoMensaje:
            "Generate a code for your friend to do the payment from the application",
        copiar: "COPY",
        volverAPagar: "Back to Pay",
        copiadoPortapapeles: "Copied to clipboard successfully.",
        habilitado: "Enabled",
        datosDeSuCuenta: "Your account details",
        tituloAlertTarjeta: "List of cards",
        tituloInfoTarjeta: "List of cards",
        mensajeAdvertenciaTarjeta:
            "Dear customer, you have exceeded the limit of cards allowed in Sion Pay or Sion Office. Contact customer service to proceed with the authorization.",
        terminoCondiciones: "Terms and Conditions",
        aceptar: "Accept",
        aceptarTerminos: "I accept the terms and conditions",
        deshabilitarPagoTarjetaMensaje:
            "You can also make your payment by: WINDOW SION, DEPOSITS AND / OR TRANSFERS, SION PAY",
        nuevaTarjeta: "New card",
        sinTarjetas: "You do not have registered cards.",
        vencida: "Expired",
        habilitada: "Enabled",
        noHabilitada: "Not enabled",
        eliminar: "Delete",
        seleccionar: "Select",
        habilitar: "Enable",
        habilitarTarjeta: "Enable card",
        cancelar: "Cancel",
        eliminarTarjeta: "Delete card",
        mensajeEliminarTarjeta: "Are you sure to delete the card {{card}}?",
        pagar: "Pay",
        detallePago: "Payment detail",
        prereserva: "PRE RESERVATION",
        reserva: "RESERVATION",
        compra: "DUES",
        codigoCvc: "Card verification code (CVC)",
        upgradeMembresia: "MEMBERSHIP UPGRADE",
        tituloNoPosible: "Payment by credit card is not available",
        mensajeNoPosible: "For amounts over {{monto}}, card payment is not available.",
        pagoQR: "Make payment by QR",
        criptoactivo: "As a client of the SION Group and in compliance with current regulations, I declare that the funds of this transaction have no origin, reason or destination in operations for the purchase or sale of crypto assets.",
    },
    generarContratoPage: {
        generarContrato: "Generate Contract",
        datosPersonales: "PERSONAL DATA",
        tituloContrato: "CONTRACT TITLE",
        planPagos: "PAYMENT PLAN",
        campoRequerido: "This field is required",
        emailIncorrecto: "The Email field has an incorrect format",
        docIdentidad: "Doc. Identity",
        nit: "Nit",
        nacionalidad: "Nationality",
        documentoExpedido: "Document Issued",
        fechaNacimiento: "Date of Birth",
        primerNombre: "First Name",
        segundoNombre: "Middle Name",
        apellidoPaterno: "Paternal Last Name",
        apellidoMaterno: "Maternal Last Name",
        direccion: "Address",
        paisResidencia: "Country of Residence",
        ciudadResidencia: "City of Residence",
        genero: "Gender",
        telefono: "Telephone",
        celular: "Cellular",
        correo: "Mail",
        referido: "Referral",
        siguiente: "NEXT",
        atras: "BACK",
        fechaInicio: "Start Date",
        fechaPago: "Payment Date",
        nro: "Nro",
        saldoCapital: "Capital Balance",
        cuota: "Fee",
        formulario: "Form",
        saldo: "Balance",
        importeCuota: "Fee Amount",
        expensa: "Expense",
        saldoExpensa: "Expense Balance",
        aceptarContrato: "Accept Contract",
    },
    formPrereservaPage: {
        formularioPrereserva: "Pre-reserve Form",
        errorPrereservar: "You cannot pre-reserve",
        errorRegistrarPrereserva:
            "Error when registering pre-reservation, please review the data",
        datosDelProducto: "Product data",
        cuotaInicial: "Initial Fee",
        producto: "Product",
        datosDelCliente: "Customer data",
        documentoIdentidad: "Identity Document",
        nacionalidad: "Nationality",
        expedidoEn: "Issued in",
        extranjero: "FOREIGNER",
        fechaNacimiento: "Date of Birth",
        nombre1: "Name 1",
        nombre2: "Name 2",
        apellidoPaterno: "Paternal last name",
        apellidoMaterno: "Maternal last name",
        paisResidencia: "Country of residence",
        ciudadResidencia: "City of residence",
        genero: "Gender",
        masculino: "Male",
        femenino: "Female",
        telefono: "Telephone",
        celular: "Cellular",
        correo: "Email",
        direccionCliente: "Customer address",
        direccion: "Address",
        cancelar: "Cancel",
        reservar: "Reserve",
        advertencia: "WARNING",
        prereservaLote: "Pre-reserve land",
        prereservaMembresia: "Pre-reserve Membership",
        prereservaCasa: "Pre-Reserve House",
        mensajePrereservaCasa: "The pre-reservation was registered correctly",
        nombre1Requerido: "Name 1 Required",
        primerNombreRequerido: "First name required",
        apPaternoVacio:
            "This non-user does not have the last name registered, please update their data",
        appPaternoMaternoRequerido: "Paternal or maternal last name Required",
        direccionRequerido: "Address is required",
        direccionNumerosLetras:
            "The Address field only allows numbers and letters.",
        celularRequerido: "Cell phone is required",
        telefonoRequerido: "Phone is required",
        soloNumeros: "Only numbers are allowed",
        minimoDigitos: "At least {is required {nroDigitos}} digitos",
        errorSinCorreo: "You do not have email assigned, please update your data",
        correoRequerido: "Email is required",
        correoInvalido: "Invalid email format",
        ciudadResidenciaRequerido: "City of residence is required",
        nacionalidadRequerido: "Nationality is required",
        fechaNacimientoRequerido: "Date of birth is required",
        generoRequerido: "Gender is required",
        paisResidenciaRequerido: "Country of residence is required",
        ciRequerido: "Issued CI is required",
        idTipoDocIdentidadRequerido: "Document type is required",
        fechaNacimientoMayorEdad:
            "Pre-reservation is only for people over 18 years of age.",
        tipoDocumento: "Document type",
        incial: "Initial",
        mensual2: "Monthly",
        mensajePaisRectrincion :"For {{pais}} this product is only available at SION OFFICE",
    },
    cambiarPasswordPage: {
        cambiarPassword: "Change Password",
        reglasPasswordTitulo:
            "The password must meet the following characteristics.",
        reglaMinimo: "Minimum 8 characters",
        reglaMayusculas: "At least one upper and lower case",
        reglaCaracteresEspeciales: "One special character",
        reglaRepetirPassword: "Confirm Password must be equal to the New Password",
        errorPassword: "The password does not meet the criteria described above",
        anteriorPassword: "Old Password",
        nuevoPassword: "New Password",
        confirmarPassword: "Confirm Password",
        errorConfirmarPassword:
            "Confirm Password must be equal to the New Password",
        cambiar: "Change",
        tituloModal: "NEW PASSWORD",
        mensajeModal:
            "The new password was registered successfully. The next time you log in you must enter your new password.",
        deAcuerdo: "OK",
    },
    mapaPage: {
        errorCargarProyecto:
            "There has been a problem loading the project, try later",
        tamanho: "Size",
        mapaProyecto: "Project Map",
        mapaDeProyecto: "Project Map",
        dimension: "Dimension",
        estadoTerrenos: "Land status",
        libre: "FREE",
        vendido: "SOLD",
        noFiltrado: "NOT FILTERED",
        cargarProyecto: "Load Project",
        caracteristicasProyecto: "This project has the following characteristics",
        leyenda: "Legend",
    },
    mantenimientoPage: {
        enMantenimiento: "IN MAINTENANCE",
        volveremosEn: "WE WILL BE BACK IN",
        dias: "Days",
        horas: "Hours",
        minutos: "Minutes",
        segundos: "Seconds",
        volverAIntentar: "Retry",
        expiraEn: "Expires in",
    },
    reducer: {
        sinAccesoInternet: "No internet access",
        errorCargarDatos: "Error loading data, please try again later",
        error444: "Error 444 elio",
        sesionExpiro: "Your session expired",
        expiroPusher: "I expired pusher session",
        expiroMovil: "Your session expired only valid for mobile",
        expiroApi: "Session expired through the api",
        intenteloMasTarde: "Try again later, please",
        cambiarIdiomaMsg:
            "The language change was successful, please log in again to continue.",
        cambioIdioma: "Change of Language",
        registrarBroker: "Register Broker",
        brokerLabel: "Broker",
        brokerHelperText: "Enter the identity card or name of the broker.",
        brokerRegistroExitoso: "The broker was registered correctly.",
        brokerDescripcion:
            "Broker is the person who collaborated in making the sale. You will receive an incentive according to company policies.",
    },
    InfoTarjetaLinkser: {},
    MensajeConfirmacion: {
        botonAceptar: "Agreed",
        tituloModal: "Attention",
    },
    AlertTarjeta: {
        descripcionExcedida:
            "we have detected that you already have {{nroTarjetas}} cards on file",
        checkDescripcion: "Pay with another card that is not listed.",
        botonConfirmacion: "I understand",
        botonAtras: "Back",
    },
    modalProjectsPorFiltros: {
        tipoComplejoAbierto: "OPEN",
        tipoComplejoCerrado: "CLOSED",
        realizarBusqueda: "Perform your search",
        seleccionarZona: "Select an area",
        seleccionarTipoComplejo: "Select a type of urbanization",
        cuotaInicial: "Initial fee in $us",
        cuotaMensual: "Monthly fee in $us",
        seleccionarComplejo: "Select your resort",
        zona: "Area:",
        tipoComplejo: "Complex type:",
        btnBuscar: "SEARCH",
        filtroCaracteristicas: "By characteristics",
        filtroComplejo: "By name",
        lotesLibres: "Vacant lots:",
    },
    prereservaMembresia: {
        meses: "months",
        pagoInicialDe: "Initial Payment of",
        cuotaMensualDe: "Monthly Fee of",
        resumen: "SUMMARY",
        pagoAlContado: "Cash Payment",
        tituloModalVacation: "Do you want to add a Kalomai Resort Membership?",
        agregarMembresiaVacation: "Add a Kalomai Resort Membership",
        seleccionarPlan: "Select Plan",
        eliminarTitulo: "Are you sure?",
        eliminarMensaje:
            "Are you sure you remove this membership from your current purchase?",
        prereservasRealizadas: "Pre-reservations Made",
        errorPrereserva: "One or more pre-reservations could not be made",
        successPrereserva:
            "The Pre-reservations of the selected memberships were registered correctly.",
        mes: "month",
    },
    mejorarPage: {
        costoAdenda: "Upgrade cost",
        abonado: "Paid",
        verAdenda: "See Addendum",
        conceptoPago: "Payment concept",
        actualizacionMembresia: "Membership upgrade",
        mejorarMembresia: "Upgrade Membership",
        modalOkTitulo: "Membership upgraded successfully",
        modalOkMensaje:
            "Your membership {{origen}} was successfully upgraded. Now you can enjoy your new membership: {{destino}}.",
        modalWarnTitulo: "Upgraded membership successfully",
        modalWarnMensaje:
            "Kalomai membership was upgraded correctly, please go through Customer Service to complete the necessary data for the Addendum.",
        modalAdendaCheck:
            "I have correctly reviewed my personal data in the ADDENDUM and I accept that the information is correct.",
        modalAdendaMessage:
            "In case any of your personal data is incorrect, please contact customer service.",
        adenda: "Addendum",
        aceptarAdenda: "Accept Addendum",
        mensajeOro: "Your payment plan will not have any modifications.",
        saldoPagar: "Balance to Pay",
        aceptarDocumentos: "Accept Documents",
        renuncia: "ACCEPTANCE OF CONDITIONS",
        documentos: "DOCUMENTS",
        modalRenunciaCheck:
            "I agree to ACCEPT THE CONDITIONS, I also confirm that my personal information is correct.",
        verDocumentos: "SEE DOCUMENTS",
    },
    rankingPage: {
        puntos: "Points: ",
        ventasUno: "¡You have ",
        ventasDos: " sales,  ",
        ventasTres: " sales to go!",
        ventasTotal: " sales !",
        sinVentaFuera: "¡Don't be left out!",
        sinVentaCarrera:
            "With only 1 sale you will be in the race for your points.",
        veneficiario:
            "The 40 best sellers of the semester who accumulate the most points will be benefited",
        notasAclaratorias: "EXPLANATORY NOTES",
        notaUno:
            "If the ACI is old, it must make at least 1 sale, from the beginning of the career until the end of the semester.",
        notaDos:
            "Note: Old ACI is considered to be that person who had made one or more sales up to one day before the start of the race.",
        notaTres:
            "If the ACI is new, he enters the career from the moment he makes his first sale, from that month he must be active and sell every month until the end of the semester",
        notaCuatro:
            "Note: A new ACI is considered to be that person who makes their first sale within the semester in which the career began..",
        notaCinco:
            "If the new or old ACI stops selling one month, the following month he must make two sales to stay in the race, otherwise he is out of it. Note: this option can only be used once, that is, if the ACI stops selling for the second time in a consecutive month, it is automatically out of the race.",
        notaSeis:
            "If the new or old ACI stops selling for a month in a row, they are out of the race.",
        notaSiete:
            "The products of the sales, which make the creditor of the ACI travel bonus, must be up to date with the first 2 monthly installments to guarantee their score. Otherwise, the sale will not be taken into account for the accumulation of race points.",
        notaOcho:
            "The monthly installments of the products that were sold outside the date range of the race do not add points for the travel bonus.",
        notaNueve:
            "All monthly installments paid (capital only), up to the date of the last review, generate points.",
        notaDiez:
            "Until the final review date, the amortizations made will generate points.",
        notaOnce:
            "Two races per year will be held for this bonus, they will be contemplated as follows:",
        notaDoce:
            "If one of the sales that add points for the race makes a Change of Sponsorship or Assignment of right, all the points of that sale will not be taken into account.",
        notaTrece:
            "If one of the sales made in the semester of each race is reversed before the indicated date of the last review, it will not be taken into account for the travel bonus score, but the ACI is considered active that month in the travel bonus review.",
        notaCatorce:
            "If the ACI is with an inactive code, blocked or with an ethics process at the time of making the trip, you will lose the Travel Bonus.",
        notaQuince: "Every $1 paid equals 1 point",
        notaDieciseis:
            "The payment of fines and surcharges does NOT generate points for the race.",
        notaDieciSiete:
            "Cash sales will add points, as if it were a sale in installments with a 10% initial fee and two minimum monthly payments paid.",
        notaSiesiOcho: "Self purchases are valid.",
        notaDieciNueve:
            "The package and routes of the trip will depend on the destination and the agency (excursions, games, souvenirs, etc. are paid by the winner).",
        notaVeinte: "The travel voucher is NON-TRANSFERABLE.",
        thSemestre: "Semester",
        thInicioCarrera: "career start",
        thFinalCarrera: "End of race",
        thFechaUltimaRevision: "Last revision date",
        thMesViaje: "month of the trip",
        porDefinir: "to define",
        preCalificado: "Pre - qualified",
    },
    contadorRegresivo: {
        quedan: "Remaining:",
        dias: "Day(s)",
        horas: "Hour(s)",
        minutos: "Minute(s)",
        segundos: "Second(s)",
    },
    portadaRanking: {
        ganaPuntos: "¡Earn points on all your sales!",
        desde: "From ",
        al: "to",
    },
    eventos: {
        validarNombreLetras: "The Full name field should only contain letters.",
        validacionIdEventoRequerido: "The Event field is required",
        validacionNombreInvitadoRequerido: "The Full Name field is required.",
        validacionNombreInvitadoMax:
            "The Full Name field cannot be longer than 255 characters.",
        validacionCiInvitadoRequerido: "The ID field is required.",
        valicacionCiInvitadoMax:
            "The ID field cannot have more than 40 characters. ",
        validacionFechaNacimiento: "The Date of Birth field is incorrect.",
        validacionFechaNacimientoRequerido: "The Date of Birth field is required.",
        invitadoRegistrado: "The guest was registered successfully.",
        listaInvitadosVacia: "There are no events enabled at this time.",
        evento: "Event",
        todos: "ALL",
        agregar: "Add guest",
        nombre: "Name",
        carnet: "ID",
        fechaNacimiento: "Date of birth",
        registrarInvitado: "Register guest",
        seleccioneEvento: "SELECT AN EVENT",
        nombreCompleto: "Full name",
        cancelar: "Cancel",
        registrar: "Register",
    },
    abonos: {
        errorStock:
            "At this moment there is no stock available for the selected Season Ticket.",
        guerreroRoyalPari: "GUERRERO ROYAL PARI",
        abono: "ABONO",
        precios: "Prices",
        beneficios: "Benefits",
        familiar: "FAMILIAR",
        guerrero: "SUPER GUERRERO",
        especial: "ESPECIAL",
        infantil: "INFANTIL",
        mensajeAlertPrereserva: "Select a price to make the pre-reservation.",
        tituloModal: "Pre-reserve subscription",
        mensajeModal: "The pre-reservation was made correctly",
        beneficio1: "Belong to the WhatsApp group of partners.",
        beneficio2: "Discounts on tickets and trips with the team.",
        beneficio3: "Welcome kits.",
        beneficio4: "Discounts on our merchandising products.",
        beneficio5: "Professional experience with the team.",
        beneficio6: "Entrance to the team trainings .",
        beneficio7: "10% SPA discount for the family at the Urbarí Clinic.",
        beneficio8: "5% discount for hospitalization at the Urbarí Clinic.",
        beneficio9:
            "Entrance to the stadium when we are local to all official matches of the div. professional or South American cup.",
        beneficio10: "10 passes to Kalomai Park per year.",
        beneficio11: "5 passes to Kalomai Park per year.",
        contado: "ONE PAYMENT",
        credito: "CREDIT",
        nroPrereserva: "Your pre-reservation number is:",
    },
    ticketsPage: {
        tickets: "Tickets",
        cupon: "COUPONS",
        tituloPrincipal: "MY TICKETS",
        ticketscupon: "Coupons",
        tituloPrincipalcupon: "MY COUPONS",
        tituloPaginaSb1: "ON TIME PAYMENT PROMOTION.",
        tituloPaginaSb2:
            "Pay your land fee for the month of October 2023 on time and you will receive 2 tickets.",
        tituloPaginaSb1cp: "ON TIME PAYMENT PROMOTION.",
        tituloPaginaSb2cp:
            "Pay your membership fee on time and receive a Virtual consumption ticket for use in Kalomai.",
        fechaini: "valid from:: ",
        fechafin: "valid until:: ",
        valido: "VALID",
        expirado: "EXPIRED",
        agotado: "EXHAUSTED",
        pendiente: "PENDING",
        informacion: "NOTE: ",
        informacion2: "To enter the KALOMAI park you must present the Ticket",
        informacion2cupon: "Vouchers must be redeemed at the park",
    },
    updateDataUser: {
        titulo: "Data update",
        subTitulo: "Please update the following information is necessary",
        labelCorreo: "Mail",
        errorCorreo: "The email is not valid",
        correoRequerido: "The email is required",
        labelPais: "Country of residence",
        textItemPais: "Select your country of residence",
        seleccionarPais: "You must select your country of residence",
        labelCiudad: "City of residence",
        textItemCiudad: "Select city of residence",
        seleccionarCiudad: "You must select your city of residence",
        labelCodigoPais: "Country code",
        idioma: "en",
        labelTelefono: "Cell phone",
        telefonoNoNumero: "Cell phone number should only be numbers",
        telefonoRequerido: "Cell phone number is required",
        telefonoMinDigitos:
            "The cell phone must not have less than {{minDigitos}} digits",
        telefonoMaxDigitos:
            "The cell phone must not have more than {{maxDigitos}} digits",
        btnActualizar: "Update data",
        textActualizacionCorrecta: "Your data has been successfully updated",
        btnAceptar: "Accept",
        textLoader: "Updating data",
    },
    consultaCiDialog: {
        textYo: "I",
        textHacerReserva: "Will make a reservation for",
    },
    datosFactura: {
        titulo: "Data for the invoice",
        msgInformativo: "Verify that your data is correct to avoid future claims.",
        labelDocumento: "Document number",
        errorDocumentoNoNumero: "Document type accepts only numbers",
        documentoMinDigitos: "Minimum document number {{minDigitos}} characters",
        documentoMaxDigitos: "Maximum document number {{maxDigitos}} characters",
        errorDocumento: "The document number is required",
        labelComplemento: "Complement",
        errorCompAlfaNum: "Error ex.: 1A",
        errorComplemento: "Max. {{maxDigitos}} chars.",
        labelTipoDocumento: "Type of document",
        textItemTipoDoc: "Select type of document",
        errorTipoDocumento: "You must select the type of document",
        labelRazonSocial: "Company name",
        razonSocialRequerido: "The company name is required",
        labelCorreo: "Email",
        errorCorreo: "The email is not valid",
        correoRequerido: "The email is required",
        labelTelefono: "Cell phone",
        telefonoNoNumero: "The cell phone must only be numbers",
        telefonoRequerido: "The cell phone number is required",
        telefonoMinDigitos:
            "The cell phone must not have less than {{minDigitos}} digits",
        telefonoMaxDigitos:
            "The cell phone must not have more than {{maxDigitos}} digits",
        btnTextEnviar: "Save data",
        textBtnVerDatos: "View my data for the invoice",
        textBtnCancelar: "Cancel",
        textDatosRegistrados: "Your data has been successfully registered",
        btnAceptar: "Accept",
    },
    simpleQR: {
        titulo: "Simple QR",
        textDescargar: "Download",
        textCompartir: "Share",
        descripcion: "To see other forms of payment click on the following button",
        textFormasPago: "Other forms of payment",
        pagoQR: "Simple QR payment",
        errorQR:
            "The QR could not be generated, You can see other forms of payment",
        pagoConfirmado: "Your payment by QR was registered correctly",
        btnAceptar: "Accept",
        textErrorAlRegistrar:
            "Payment by QR has been confirmed and is in the process of regularization",
        nroTransaccion: "Transaction No.:",
        fechaVencimiento: "Due date:",
        monto: "Amount:",
        conceptoPago: "Concept: Payment",
        tituloQR: "PAY BY QR",
        noGenerado: "Could not generate QR code",
        reintentar: "Try again",
        cerrar: "Close QR",
    },
    carnetKalomaiPage: {
        tituloAlertSinKarnet: "KALOMAI ACCREDITATION",
        cuerpoAlertSinKarnet:
            "If you have a Kalomai membership, please contact customer service to regularize your KALOMAI ACCREDITATION.",
    },
    membresiasCabanasPage: {
        titulo: "My memberships",
        subtitulo: "Select the membership with which you will access the reservation of your cabin.",
        mensajeEnMora: "Dear customer, you have overdue payment installments, get up to date to access the benefits of reserving a cabin.",
        mensajeMembresiasVacias: "You do not have memberships to reserve a cabin.",
        mensajeNoTieneCarnetVigente: "Dear customer, you do not have a valid identity card, contact customer service to update your information.",
        labelProducto: "Product: "
    },
    portadaBonoMejorVendedor: {
        ganaPuntos: "¡Best Seller Bonus!",
        desde: "From the",
        al: "to",
        validezCicloBono: "From February 18 to July 31, 2023",
    },
    bonoMejorVendedorPage: {
        puntos: "Points: ",
        ventasUno: "¡You ve got",
        ventasDos: "sale(s), you re missing ",
        ventasTres: "more!",
        ventasTotal: " sales !",
        sinVentaFuera: "Dont be left out!",
        sinVentaCarrera:
            "Concrete your sales, be part of the 30 best of the month and win the $300 BONUS",
        veneficiario:
            "All independent business advisers who reach the goal in the amount of sales of membership and/or land included in the following dates will be beneficiaries:",
        politicas: "POLICIES OF THE WAY TO EARN BONUS BEST SELLERS",
        mejoresVendedores:
            "The top 30 sellers in amount each month will be given $300 in cash",
        notasAclaratorias: "ExplanatoryNotes:",
        notaUno:
            "To access the bonus, the ACI will have to be among the best sellers in amount of the month.",
        notaDos:
            "Advisor must be current on their personal products to receive the bonus.",
        notaTres: "Only personal sales are valid.",
        notaCuatro:
            "Only sales with a contract generated until the end of each month will be taken into account.",
        notaCinco:
            "The bonus will be delivered every month together with the payment of commissions.",
        notaSeis: "Sales in installments and cash are valid.",
        notaSiete:
            "To receive the bonus, the ACI must invoice, otherwise the corresponding legal withholding will be made.",
        notaOcho: "The final list is subject to verification and company policies",
        thSemestre: "Semester",
        thInicioCarrera: "Start career",
        thFinalCarrera: "End of career",
        thFechaUltimaRevision: "Last revision date",
        thMesViaje: "Month of the trip",
        porDefinir: "to be defined",
        posicionPreliminar: "Your current position",
        condicionesGenerales: "GENERAL CONDITIONS",
        condicionGeneralUno:
            "Only sales with a contract generated within the dates established for the incentive will be taken into account for the campaign.",
        condicionGeneralDos:
            "The ACIs that are winners of the incentive must not have an inactive, blocked, expelled or ethical code at the time of receiving the incentive, otherwise they will lose it.",
        condicionGeneralTres:
            "If one of the sales of the clients of the incentive campaign has a transfer of rights, change of sponsor, rescheduling, rescheduling, termination of the contract or transfer of capital, it will not be counted for the bonus.",
        monto: "Amount: ",
        posicionActual: "YOUR CURRENT POSITION IS:",
        tienes: "¡YOU HAVE",
        enVenta: "$us. FOR SALE!",
        debesEstar: "YOU MUST BE IN THE TOP 30 TO RECEIVE THE BONUS",
    },
    upgradeTravel: {
        precioTotal: "Total Price",
        totalPagado: "Total Paid",
        totalDeuda: "Total Debt",
        precio: "Price",
        descuento: "Discount",
        precioFinal: "Final Price",
        cuotaInicial: "Initial Installment",
        saldo: "Balance",
        totalFinanciar: "Total to Finance",
        cuotaMensual: "Monthly Installment",
        plazo: "Term",
        simularAdenda: "Simulate Addendum",
        btnAtras: "Back",
        btnPagarInicial: "Pay down payment",
        agotado: "SOLD OUT",
        textMensaje: "Keep an eye out for new releases",
        btnVolverAtras: "Back to my products",
        txtPagoRealizado: "Your payment was successful",
        txtVerNuevoProducto: "You can now view your new product",
        btnMisProductos: "My products",
        detalleUpgrade: "DETAIL UPGRADE",
        pagarUpgrade: "PAY UPGRADE",
        labelPorcentaje: "Initial Percentage",
        textAlContado: "Cash",
        btnAlContado: "Pay Cash",
        textMeses: "Months",
        tituloModalTravel: "UPGRADE TRAVEL MEMBERSHIP",
        verTravel: "Upgrade Travel",
    },
    beneficioTravel: {
        btnActivarBeneficio: "Activate Benefit",
        precio: "Price",
        pagado: "Paid",
        deuda: "Debt",
        cuotasVencidas: "Past Due Installments",
        beneficios: "Benefits",
        btnVerBeneficio: "View my benefits",
        tituloBeneficio: "Available Benefits",
        btnAdquirir: "Acquire Benefit",
        noHabilitadoTitulo: "Not all benefits are enabled",
        noHabilitadoMensaje: "To qualify the benefit    , you must have paid {{porcentaje}}%  of your product and have no outstanding installments.",
        cerrar: "Close",
        tituloMiBeneficio: "My Acquired Benefits",
        detalle: "Benefit Detail",
        codigo: "RCI Code",
        fechaVencimiento: "Expiration Date",
        mensajeConfirmacion: "You can now view your new benefit",
        cerrarPago: "Close Payments",
        pagoBeneficio: "Benefit Payment",
        tipoPago: "Payment Type",
        verMas:'View More',
        verMenos: 'See Less'
    },
    calcularRecuperacion: {
        textoMenu: "Calculate Recovery",
        calculadora: "Recovery Calculator",
        textPlaceholder: "Search by C.I.",
        nombre: "Name",
        ci: "C.I.",
        nroVenta: "Sale No.",
        producto: "Product",
        fechaContrato: "Contract Date",
        totalPagado: "Total Paid",
        totalDeuda: "Total Due",
        montoReconocido: "Amount Recognized",
        btnBuscarProyectos: "Search Projects",
        proyectos: "Projects",
        productos: "Products",
        btnCerrar: "Close",
        mensajeAlert: "No projects found",
        precioLista: "List Price",
        descuento: "Discount",
        precioVenta: "Sale Price",
        saldo: "Balance to finance",
        restaurar: "Restore Values",
        sinReversiones: "No reversed products",
        tituloDialogComparar: "Reversed Product / New product",
    },
    pagoStripe: {
        tituloModal: 'Beneficiary payment',
        procesando: 'Processing ',
        pagar: 'Pay',
        pagoMensaje: 'Your benefit payment was successfully recorded',
        pagofallido: 'Your payment is being processed.',
        mailInvalido: 'Select a valid e-mail address',
        mailConfirma: 'Confirm e-mail',
        correoElectronico: 'email address',
        mensajeCorreo: 'You will receive the information of your payment, to the selected email.',
        selectTextMail: 'E-mail address',
        selectMailVacio: 'Select an e-mail address'
    },
    eventosACI: {
        etiquetaCrearPaso1: "Title, place and spots",
        etiquetaCrearPaso2: "Address and date",
        etiquetaCrearPaso3: "Staff , speaker and contact number",
        etiquetaCrearPaso4: "Support Speaker",
        dirrecionTitulo: "Event Address",
        nombreTitulo: "Event Title",
        ciudadTitulo: "City.",
        nombreOrganizadorTitulo: "Nombre del organizador.",
        celularOrganizadorTitulo: "Contact phone number",
        disertanteTitulo: "Speaker's Name",
        fechaHora: "Date",
        capacidadTitulo: "Avaible spots",
        tituloDatos: "Basic data",
        tituloDatosDirreccion: 'About the meeting',
        tituloDatosOrganizador: "About Staff",
        tituloDatosDisertante: "(Optional) Helper Speaker",
        disertanteBuscar: 'Search for a speaker',
        tituloBuscarCI: "Search by ID",
        placeHolderDisertante: "Enter the client ID",
        duracionLabel: "Event lenght in hours",
        dialogCreateTitle: "Create",
        dialogCreateOK: "Yes, Create",
        dialogCreateCancel: "No",
        dialogDisertanteTitle: "Set as Speaker.",
        dialogDisertanteOk: "Yes, set as Speaker!",
        dialogInfoSearchNotFound: "No person found",
        dialogInfoSearchAgain: "Please. Try again!",
        dialogErrorMessage: "Error!",
        dialogInvitarOk: "Yes, invite",
        dialogInvitarCancel: "No, I will do it later",
        dialogInvitarTitle: "Invite lead",
        homeTituloMisEventos: "Next events",
        campoDireccion: 'Place',
        campoCiudad: 'City',
        campoFechaHora: 'Date',
        campoDuraccion: 'Lenght',
        campoCapacidad: 'Spots',
        campoOrganizador: 'Staff',
        campoDisertante: 'Speaker',
        campoTelefono: 'Phone number',
        campoEmail: 'Email address',
        campoNombre: 'First name and last name',
        campoInvitadoCI: 'Person ID',
        tituloInvitados: 'Lead list',
        tablaInvitadoNumero: 'N',
        tablaInvitadoNombre: 'Number',
        tablaInvitadoEmail: 'Email',
        tablaInvitadoTelefono: 'Phone',
        tablaInvitadoFechaInvitacion: "Invited date",
        tablaInvitadoPrePatrocinador: 'Lead owner',
        tablaInvitadoCiudad: 'City',
        tablaInvitadoEvento: 'Event',
        nuevoInvitado: 'Invite my lead',
        invitar: 'Invite',
        salaEventoTitulo: 'Event room',
        salaAdministrar: 'Modified my room',
        invitarTo: 'Invite',
        eventos: 'Rooms',
        organizarTitulo: 'Organize a new bussiness meeting',
        unirseSala: 'Join to event room',
        crearEvento: 'Create event room',
        unirseEventoSubHeading: 'Join to a near Bussiness meeting. First select a city',
        eventosNoRooms: 'There are not Bussiness meeting on this city.',
        proximosEventos: 'Next events:',
        unirmeEvento: 'Join to this room',
        salaHeading: 'Increase your sales running events',
        unirmeEventoSucces: 'Congratulations you were add to the event room',
        crearEventoPropio: 'Create my own room',
        unirmeto: 'Join to:',
        dialogUnirmeOk: 'Yes, I would like to join.',
        dialogUnirmeCancel: 'No, I will do later',
        dialogJoinedSuccess: 'Congratulations you were add to the event room :',
        btnSala: 'See room',
        landingEventosHeading: 'Customs bussines meeting.',
        landingEventosSubHeading: 'Increase your sales with bussines meeting. Create your control room for your bussiness meeting , or join to another bussines room meeting.',
        langdingCrearHeading: 'Organize your own meeting, create your room, add collaborators, bring your leads and increase your sales.',
        estadoEventoCancelado: 'Canceled',
        estadoEventoFinalizado: 'Finished',
        estadoEventoEncurso: 'On going',
        estadoEventoProgramado: 'Scheduled',
        salaAdministrarTituloFecha: "Modified date and meeting lenght",
        salaAdministrarTituloDireccion: "Modified city and address",
        salaAdministrarTituloGeneral: "Modified title and spots",
        salaAdministrarTituloDisertante: "Modified speaker",
        dialogModificar: "Yes, modified",
        dialogModificarTitleFrom: "Change title of",
        dialogModificarTo: "to",
        accionModificar: "Modified",
        accionConjuccion: "and",
        dialogModificarCapacidadFrom: "Change available spots",
        dialogSinCambios: "Nothing to change!",
        ciudadActual: "Current city",
        dialogModificarCiudadFrom: "Change city",
        dialogModificarDireccionFrom: "Change place",
        dialogModificarFechaFrom: "Change date",
        dialogModificarDuraccionFrom: "Change lenght",
        dialogModificarDisertanteFrom: "Change speaker",
        dialogModificarEventoSuccess: "Congratulatios the room was modified!",
        dialogConfirmarDisertante: "Set as speaker ",
        accionNo: "No",
        accionCancel: "Yes, cancel it",
        btnCancelar: "Cancel this event",
        btnRetirar: "Removed lead's invitation",
        btnCrearEvento: "Create room",
        btnValidar: "Check",
        btnRegresar: "Return",
        habilitarCancelar: "Ennable cancel",
        dialogCancelarTitulo: "Cancel",
        dialogCancelarSuccess: "The event was Cancel",
        cargandoEvento: "Loading",
        autoInvitacionError: "Can't invited your self",
        disertanteComoprospecto: "The lead cannot be ACI",
        asdisertante: "as speaker?",
        asCapacidad: "with spots",
        volverInicio: "Return to begging",
        presionaParaFinalizar: "Almost done press create button to finish",
        verInvitados: "See lead list",
        infoStaff: "About Staff",
        creacionExitosa: "The meeting has been scheduled",
        historialHeading: "Historial",
        historialSubheading: "Room history, leads history and more",
        historialEventos: "Room's Historry",
        historialEventosSubheading: "Your rooms history as creator or as ACI",
        historialInvitados: "Your leads records",
        historialInvitadosSubheading: "Leads invited records",
        tablaInvitadoDias: "Days",
        tablaInvitadoEstado: "Stage",
        estadoInvitacionAbierta: "Registered",
        estadoInvitacionVencida: "Finished",
        estadoInvitacionConvertida: "Converted",
        estadoInvitacionRetirada: "Removed",
        estadoInvitacionIndeterminado: "Unknown",
        estadoInvitaconNodefinido: "Undefinied",
        administrarInvitacion: "Modify invitation",
        enviarInvitacion: "Invited lead",
        dialogModificarInvitacionOk: "Yes, Modify",
        dialogModificarProspecto: "Modify lead",
        dialogRetirarProspecto: "Remove lead",
        checBoxRetirarInvitacion: "Ennable retreat invitation",
        dialogRetirarInvitacionOk: "Yes, retreat invitation",
        dialogAbandonarSala: "Left the room",
        dialogAbandonarSalaWarning: "All invitations to this room will retreat if you left the room",
        dialogAbandonarSalaAction: "Yes, left room",
        dialogAbandonarSalaSuccess: "Left the room",
        salaBtnControlAsistencia: "Attend control",
        tituloPaginaControlAsistencia: "Attend control",
        paginaControlAsistenciaSubheading: "Take list",
        leyendaAsistencia: "You can see complete list one hour before the event start",
        btnConfirmarAsitencia: "attend",
        btnNoAsistio: "Unattend",
        dialogConfirmarAsistencia: "Check attend",
        dialogConfirmarAsistenciaSub: "Seleciona una invitacion",
        tituloProspectoDatosContacto: "Contact data",
        textoAsistencia: "attend",
        textoNoAsistencia: "Unattend",
        textoNoDefinado: "",
        prospectoContactoCorreo: "Only email",
        prospectoContactoDual: "Email Address and phone number",
        prospectoContactoTelefono: "Only Phone number",
        aclaracion2Disertante: "Could be room owner or another ACI",
        btnVerificar: "Check",
        moderardorSala: "Is Moderator",
        moderardorSalaSubHeading: "Moderator can take list",
        moderadorDialogTitle: "Modify person's permissions",
        moderadorDialogOk: "Yes, Modify this permissions",
        moderadorDialogContent: "Modify permissions",
        moderadorDialogContentDone: "Permissions were changed",
        btnOmitir: "Skip step",
        TituloAdministrarSala: "Manage room",
        segundoDisertante: 'Helper speaker',
        eliminarSegundoDisertante: 'Remove helper speaker',
        mostrando: 'Showing',
        de: "of",
        errorDireccionNula: "The address is empty",
        errorDireccionLongitudCorta: "The address is too short",
        errorDireccionLongitudLargo: "The address is too long",
        errorFechaFueraRango: "Date out of range",
        errorDuracionFueraRango: "Lenght out of Range",
        errorIngresaUnCIValido: "Enter a valid person ID",
        errorDisertanteYOrganizador: "The organizer cannot be the main speaker",
        errorModeloEvento: "Please. select a kind of event",
        errorSeleccionCiudad: "Please. select a city",
        warningCapacidad: "The room's capacity must be between 5 and 999",
        errorAgenda1Part1: "Cannot schedule a event on this date because the main speaker already have an event schedule",
        errorAgenda1Part2: "on",
        errorAgenda1Part3: "the meeting lenght is ",
        errorAgenda1Part4: ", please select another speaker",
        errorAgenda2Part1: "Cannot schedule a event on this date because the helper speaker already have an event schedule ",
        errorAgenda2Part2: "on",
        errorAgenda2Part3: "the meeting lenght is",
        errorAgenda2Part4: ", please select another helper speaker",
        errorNoACI: "The person is not an ACI",
        errorProspectoCorreo: "Enter lead email address",
        errorAbandonoSala: "Error while trying to left the room",
        errorFormatoCI: "Wrong format for person ID",
        errorFormatoCorreo: "Wrong format for email address",
        errorProspectoCelular: "You have to supplied phone number",
        errorFormatoCelular: "Wrong phone number",
        datosProspecto: "Lead contact data",
        errorRazonCancelar: "You must supplied a description to continue",
        errorCalendarioCreador: "Cannot schedule the meeting {{nombre}} because you already have a meeting  schedule at {{fechaHora}} with lenght {{duracion}} hrs",
        errorCalendarioDisertante: "Cannot schedule the meeting {{nombre}} because the main speaker has a meeting  schedule at {{fechaHora}} with lenght {{duracion}} hrs",
        errorCalendarioDisertante2: "Cannot schedule the meeting {{nombre}} because the helper speaker has a meeting  schedule at {{fechaHora}} with lenght {{duracion}} hrs",
        errorFormatoNombre: "Wrong name length. the name length should be between 5 and 120 characters",
        mensajeSalaAsignarDisertante: "Set : {{nombre}} as speaker?",
        btnOk: "Ok",
        textoNuevoDisertante: "New Speaker",
        mensajeAsistenciaConfirmada: "Attended",
        mensajeFaltaConfirmada: "UnAttended",
        mensajeModificacionPermisos: "{{nombre}}'s Permissions were modified",
        mensajeFiltrarNombre: "Ingresa un criterio de busqueda",
        mensajeRetirarProspecto: "{{nombre}} identified by id {{ci}} withdrawn successfully",
        mensajeModificarProspecto: "{{nombre}} identified by id {{ci}} modified successfully",
        btnSiguiente: "Next",
        btnContinuar: "Continue",
        successProspectoInvitacion: "The person {{nombre}} identified by id {{ci}} created as Lead successfully",
        campoID: "CI",
        errorInicialFecha: "Rooms meeting should be scheduled 12 hours in advance. Select another date",
        crearEventoDialogo: "Would you like creating Business event,¿{{nombre}} for independent business advisors (ACI) for {{capacidad}} guests?",
        documentoIDPlaceHolder: "Enter your national identity document",
        documentoIDLabel: "National document ID"
    },
    promocion2X1Septiembre: {
        selecciona2doProd: "Select 2nd Product",
        complejo: "Complex",
        producto: "Product",
        manzano: "Apple Orchard",
        nroLote: "Lot Number",
        superficie: "Surface",
        productoSeleccionado: "Selected Product",
        seleccionandoProducto: "Selecting the Product:",
    },
    datosUif: {
        otrosDatos: "OTHER DATA",
        ciudadNacimiento: "Birth City",
        profesion: "Profession",
        actividadComercial: "Business Activity",
        funcionarioPublico: "Are you currently or have you been a public official in the last 5 years?",
        nombreInstitucionPublica: "Public Institution Name",
        cargo: "Position",
        declareInfoAccurateTrustworthy: "I declare as a customer that the personal information provided is accurate and trustworthy.",
    },
    rooms: {    
        noEligle: "We are sorry, you do not meet all the necessary requirements to access this functionality.",
        nroSala : "Room id",
        enlaceCopiar: "Copy your invitation link",
        enlaceCopiado : "!Link copied to clipboard!",
        freelancers:    "Freelancers",
        invitados  : "Leads",
        titulo     : "Room id #{{id}}",
        tipoSala   : "Meeting type",
        searchText : "Filter by name",
    },
    cardPreReserva:{
        botonEnlace:'Link',
        tituloModalEnlace:'You want to generate a pre-booking payment link for the product.',
        linkPago:'GENERATE PAYMENT LINK',
        valido:'Valid until',
        tituloModalEnlaceReserva:'You want to generate a product reservation payment link!',
    },
    verificarPAgoFacil:{
        proyecto:'PROJECT',
        producto:'PRODUCT',
        nro:'No.',
        tipo:'Guy',
        monto:'Amount',
        irInicio:'Go to the main page'
    }
};
