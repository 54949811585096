export default {
    homePage: {
        eventos: "Eventos",
        capacitacion: "Treinamento",
        ranking: "Ranking",
        verImagen: "Ver imagem",
        verMas: "VER MAIS",
        verMenos: "VEJA MENOS",
        espanhol: "Espanhol",
        ingles: "Inglês",
        portugues: "Português",
        principal: "Começar",
        terrenos: "Terra",
        membresias: "Associação",
        casas: "Casas",
        nuestrosProductos: "nossos produtos",
        meInteresa: "ESTOU INTERESSADO",
        novedades: "Novidades",
        terrenosText:
            "Obtenha o seu terreno, localizado na melhor zona da sua cidade, pense no seu futuro e no dos seus entes queridos, pense grande, porque se eu puder ... você pode!",
        membresiasText:
            'Agora você pode se tornar membro do "KALOMAI PARK", o melhor parque aquático da Bolívia localizado no departamento de Santa Cruz de la Sierra.',
        casasText:
            "Adquira a sua moradia nova, com financiamento e a um preço incrível nos melhores empreendimentos urbanos localizados na nossa cidade de Santa Cruz de la Sierra.",
        abonosText:
            "Acompanhe a equipe ROYAL PARI comprando sua assinatura para uma gestão inteira. Faça parte desta paixão pelo futebol.",
        ultimasNoticias: "ÚLTIMAS NOTÍCIAS",
        nuestrasApps: "NOSSOS APLICATIVOS",
        empresas: "EMPRESAS",
        empresasSocias: "EMPRESAS PARCEIRAS",
        direccion: "Endereço Av. Cañoto N673",
        telefono: "Telefone",
        oficinaVirtual: "Escritório Virtual",
        siguenos: "SIGA-NOS",
        pagar: "Meus produtos",
        productos: "PRODUTOS",
        perfil: "PROFILE",
        productosVendidos: "Produtos Vendidos",
        liberarReservas: "Liberar reservas",
        tarjetasPrepago: "Cartões pré-pagos",
        proyectosCasas: "Proyectos Casas",
        antiguoPortal: "Portal Antigo",
        cerrarSesion: "Fechar Sessão",
        cambiarPassword: "Alterar senha",
        verCasas: "Ver Casas",
        verTerrenos: "Ver Terrenos",
        verMembresias: "Ver Associações",
        pagaTuReservaCon: "Pague sua reserva com",
        terreno: "Terra",
        casa: "Casa",
        abonosRP: "Associação Royal Pari",
        reservasCabana: "Reserve uma cabine",
        tramite: "Formalidades",
        idioma: "Português",
        sesionInactividadTitulo: "Sessão expirou",
        sesionInactividadMensaje: "Sua sessão expirou devido à inatividade.",
        sessionInactividadBoton: "OK",
        miCarnetkalomai: "Acreditação Kalomai",
        descargarCarnet: "Baixar",
        redClientes: "Rede do clientes",
        bonoMejoresVendedores: "Bônus mais vendido",
        membresiaInternacional: "Associação Internacional",
        botonPagar: "Pagar",
        eventosACI: "Sala de reuniões"
    },
    proyectosPage: {
        adquiereTuTerreno: "ADQUIRA SUA TERRA",
        verLista: "Ver na lista",
        verMapa: "Ver Mapa",
        verEnMapa: "Ver Mapa",
        verPlano: "Ver Plano",
        filtros: "Filtros",
        filtrosBusqueda: "Filtros de pesquisa",
        manzano: "Macieira",
        lote: "Terra",
        producto: "Produto",
        precio: "Preco",
        desc: "Desc",
        precioDesc: "Preco Desc",
        inicial: "Inicial",
        mensual: "Mensal",
        prereservas: "Pré-reservas",
        prereservar: "Pré-reservar",
        orden: "Pedido",
        plazo: "Termo",
        estado: "Status",
        superficie: "Superfície",
        buscar: "Procurar",
        seleccione: "Selecionar",
        precioMasBajo: "Preço mais baixo",
        precioMasAlto: "Preço mais alto",
        terrenosPequenhos: "Parcelas menores",
        terrenosGrandes: "Terra maior",
        oro: "Ouro",
        contado: "Contado",
        contadoPorcentage: "Contado {{porcentaje}}% de desconto",
        inicialDescuento: "3 anos 20% de desconto {{initial}}% da taxa inicial",
        todos: "tudo",
        terrenosPrereserva: "Terra pré-reservada",
        terrenosLibres: "Terra Livre",
        filtrar: "Filtrar",
        nroManzano: "Macieira N",
        codigoProducto: "CÓDIGO DO PRODUTO",
        formasCompra: "Formas de Compra",
        verPrereservas: "Ver Pré-reservas",
        nroPrereserva: "Nro de pré-reserva",
        fechaRegistro: "Data de registro",
        resultadoFiltroCaracteristicas:
            "encontrou {{encontrados}} de {{total}} lotes livres",
        resultadoFiltroComplejos: "{{total}} lotes livres",
        nroReserva: "Nro. da reserva",
        nroVenta: "Nro. Venda",
    },
    membresiasPage: {
        contado: "Contado",
        credito: "Crédito",
        descuento: "Desconto",
        precioCDescuento: "Preço C/Desconto",
        porcentajeInicial: "Porcentagem inicial",
        plazo: "Termo",
        cuotaInicial: "Quota Inicial",
        cuotaMensual: "Taxa mensal",
        simularContrato: "Simular Contrato",
        simularPlan: "Simular Plano de Pagamento",
        reservar: "Reservar",
        vista360: "Vista 360",
        anterior: "Anterior",
        simulacionContrato: "Simulação de contrato",
        simulacionPlanPago: "Simulação de plano de pagamento",
        aceptar: "Aceitar",
        coloqueCi: "Coloque sua carteira de identidade",
        ahorro: "Salvando",
        precioAhora: "Agora",
        precioAntes: "Antes",
        meses: "Meses",
        montoInicialInvalido: "Deve introduzir um valor válido para continuar",
        montoInicialMayorValidacion:
            "O montante inscrito não pode ser superior ou igual ao montante do rendimento de $us ",
        montoInicialMenorValidacion:
            "O montante pago não pode ser inferior ou igual ao montante do adiantamento de $us ",
        mensajeTooltipInicial:
            "Pode agora editar o montante do seu adiantamento, este deve ser superior a 10% do valor do produto.",
        subtitulodiagonalTarjeta: "Oferta",
        nuevaInicial: "Nova inicial",
        saldoAFinanciar: "Saldo para financiar",
        restriccionesAplican: "Restrições aplicadas",
        nuevoInteres: "Juros para novas vendas de {{fecha}}",
        nuevaCuotaMensual: "Nova mensalidade",
        errorMora: 'O cliente com CI {{ci}} tem quotas em atraso, só é permitido fazer reservas clientes que estejam em dia..',
        alContado: "Em dinheiro",
        alertDescuento: "Como membro do Grupo Sion, você recebe um desconto adicional.",
        sinConfiguracion: "Nenhuma configuração encontrada",
        primeraCuota: "Primeira instalação",
        promocion2x1: "Promocion 2 x 1"
    },
    casasPage: {
        adquiereTuCasa: "Adquira sua casa",
        codigoProducto: "Código do produto",
        interesados: "Interessado",
        precioTotal: "Preço Total",
        verFicha: "Ver Arquivo",
        seleccionar: "Selecionar",
        tituloModelosCasas:
            "No lote {{nroManzano}} macieira {{nroLote}} os seguintes modelos de casas podem ser construídos",
        informacion: "INFORMAÇÕES",
        caracteristicas: "Características",
        planDePagos: "PLANO DE PAGAMENTO",
        detalleCuotaInicial: "Detalhe da taxa inicial",
        desgloseCuotaInicial: " Repartição de 3% da prestação inicial",
        saldoPagar: "Saldo a pagar em 7 dias",
        financiamientoDirecto: "Financiamento direto de 7%",
        cuotasMensuales: "5 prestações mensais de",
        detalleFinanciamiento: "Detalhe do financiamento",
        totalVivienda: "Moradia Total",
        totalAFinanciar: "TOTAL PARA FINANCIAR",
        cuotaMensualAproximada: "Pagamento mensal aproximado",
        nota: "Nota",
        mensajeNota:
            "Os dados do pagamento inicial e mensal são referenciais e sujeitos a modificações conforme análise bancária",
        simulacionPlanPagos: "Simulação do plano de pagamentos ",
        montoFinanciar: "Valor a ser financiado",
        monto: "Valor",
        continuar: "Prosseguir",
        certificadoCancelacionTotal: "Certificado de cancelamento total",
    },
    pagarPage: {
        prereserva: "Pré-reserva",
        reserva: "Reserva",
        compra: "Compra",
        totalPrecioProducto: "Preço total do produto",
        cuotaMensual: "Taxa mensal",
        expensa: "Despesa",
        pagadoACuenta: "Pago na conta",
        pagarSaldoInicial: "Pague o saldo da taxa inicial com",
        validez: "Validade",
        mesesTranscurridos: "Meses passados",
        reconfigurarPlanPagos: "Reconfigure o plano de pagamento",
        historicoPagos: "Histórico de pagamento",
        comprobante: "Comprovante",
        nombreProducto: "Nome do produto",
        codigoProducto: "Código do produto",
        nroVenta: "Nro. Venda",
        nroRecibo: "Recibo nº",
        fechaPago: "Data de pagamento",
        aCuenta: "Em conta",
        amortizado: "Amortizado",
        multa: "Multa",
        interes: "Juros",
        totalPagado: "Total pago",
        fecha: "Data",
        compras: "Compras",
        totalDeuda: "Dívida Total",
        cuotasVencidas: "Parcelas vencidas",
        saldoCuotaInicial: "Saldo da taxa inicial",
        financiamientoDirecto: "Financiamento Direto",
        saldoFinanciar: "Saldo a ser financiado",
        totalCuotaInicial: "Taxa inicial total",
        fechaLimite: "Data Limite",
        montoRestanteInicial: "Valor Restante a Pagar Inicial",
        fechaContrato: "Data do contrato",
        mejorar: "Melhorar",
        mensajePrereservaAbono:
            "Para continuar, você deve efetuar o pagamento de sua assinatura por caixa ou por depósito bancário em contas do clube Royal Pari.",
        mensajeLimitePagoTarjeta:
            "Devido a restrições bancárias, não é permitido efetuar pagamentos com valores superiores a {{monto}} $us.",
        pagoRestringido:
            "Devido a manutenção, os pagamentos poderão ser feitos a partir de {{fecha}}",
    },
    loginPage: {
        iniciarSesion: "Iniciar sessão",
        usuario: "Usuário",
        password: "Senha",
        ingresar: "Login",
        olvideMiPassword: "Esqueci minha senha",
        verificarCi: "VERIFICAR CI",
        metodoRecuperacion: "MÉTODO DE RECUPERAÇÃO",
        finalizar: "FINALIZAR",
        carnetIdentidad: "Cartão de Identidade",
        siguiente: "Próximo",
        enviarCodigo: "Enviar código",
        enviarCodigoVerificacion: "Enviar código de verificação para:",
        mensajeCodigoEnviado:
            "Um código de verificação foi enviado para a opção selecionada.",
        verificar: "Verificar",
        atencion: "Atenção",
        mensajeSinCorreo:
            "Você não possui um endereço de e-mail, atualize suas informações entrando em contato com o atendimento ao cliente.",
        mensajeCorreoNoValido:
            "O e-mail selecionado tem não parecem ser válidos, atualize suas informações para entrar em contato com o atendimento ao cliente ou selecione outra opção de recuperação de senha.",
        atencionCliente: "Atendimento ao cliente",
        cerrar: "Fechar",
        ingresarNuevoPassword: "INSIRA SUA NOVA SENHA",
        mensajeReglasPassword: "A senha deve atender às seguintes características.",
        caracteresMinimos: "Mínimo 8 caracteres",
        almenosMayusculaMinuscula: "Pelo menos uma maiúscula e minúscula",
        caracterEspecial: "Um caractere especial",
        confirmarPassword: "A senha deve ser igual a Confirmar senha",
        errorPassword: "A senha não atende aos critérios descritos acima",
        tituloNuevoPassword: " NOVA SENHA",
        confirmarPasswordLabel: "Confirmar senha",
        mensajeCambioPassword:
            "A nova senha foi atualizada com sucesso. Faça login com suas novas credenciais.",
        deAcuerdo: "OK",
        ingresarCodigoVerificacion: "Digite o código de verificação",
        mensajeUsuarioNuevo:
            "Para cuidar de suas informações, você deve criar uma SENHA segura. Um código de verificação será enviado.",
        ingresarAntiguoPortal: "Vá para o antigo portal",
        clickAqui: "Clique aqui",
        problemasIngresarPortal:
            "Se você tiver qualquer problema, pode retornar ao Portal Antigo.",
        contactanos: "Entre em contato",
        modalContactoTitulo: "Estamos aqui para ajudá-lo",
        modalContactoContenido:
            "Entre em contato com o atendimento ao cliente para tirar suas dúvidas.",
        modalReintentoTitulo: "Atenção",
        modalReintentoContenido:
            "Se você não lembra sua senha, recomendamos a opção:",
        inicioDeSesion: "Login com",
        huella: "impressão digital",
        preguntaActivarHuella:
            "Quer ativar o login com impressão digital na sua conta?",
        preguntaActivarFaceId: "Quer ativar o login com Face ID na sua conta?",
        subituloModalHuella:
            "Lembre-se de que você pode ativar ou desativar essa opção em seu perfil de usuário.",
        activar: "Ativar",
        modalCarnet:
            "Caro cliente, é aconselhável definir uma senha de acesso seguro. Você tem um prazo de {{dias}} dias para realizar esta ação.",
        modalCarnetUnDia:
            "Caro cliente, é aconselhável definir uma senha de acesso seguro. Você tem um prazo de {{dias}} dia para realizar esta ação.",
        nuestraApp: "NOSSO APLICATIVO",
        textoCodigoEtica: "Eu aceito o código de ética",
        verCodigoEtica: "Veja",
        errorCodigoEtica: "Você deve aceitar o código de ética.",
    },
    notificacionPage: {
        notificaciones: "Notificações",
        cargarMas: "Carregar mais",
        tituloPaginaNotificacion:
            "Aqui você pode ver as mensagens que o Grupo Sion enviou para você",
        sinNotificaciones: "Sem notificações",
        marcarLeido: "Marcar como lida",
        verMas: "Ver mais",
        cargando: "Carregando",
    },
    perfilPage: {
        nombreCompleto: "Nome completo",
        ci: "CI",
        telefono: "Telefone",
        correo: "Email",
        celular: "Celular",
        direccion: "Endereço",
        mensajeInformacionPerfil:
            "Qualquer alteração de dados nas informações do seu perfil, entre em contato",
        calificacion: "Qualificação: ",
    },
    tarjetaPrepagoPage: {
        campoRequerido: "Este campo é obrigatório",
        textoCaptcha: "O texto inserido não corresponde ao captcha",
        tituloPagina:
            "Digite o código do seu cartão para verificar o saldo disponível.",
        mensaje: "Mensagem",
        saldo: "Saldo",
        consultarSaldo: "Verificar saldo",
        codigoTarjeta: "Código do cartão",
        codigoCaptcha: "Código Captcha",
    },
    productosVendidosPage: {
        producto: "Produto",
        cliente: "Cliente",
        telefono: "Telefone",
        email: "Email",
        fecha: "Data",
        estado: "Status",
        comisionable: "Comissionável",
        totalVenta: "Venda Total",
        venta: "Venda",
        prereserva: "PRÉ-RESERVA",
        reserva: "RESERVA",
        manzano: "Macieira",
        lote: "Lote",
        superficie: "Superfície",
        precio: "Preço",
        descuento: "Desconto",
        totalPrecioProducto: "Preço total do produto",
        cuotaInicial: "Taxa inicial",
        cuotaMensual: "Taxa mensal",
        expensa: "Despesa",
        pagado: "Pago",
        saldo: "Saldo",
        fechaVencimiento: "Data de expiração",
        nombre: "Nome",
        ci: "CI",
        liberarPrereserva: "Pré-reserva de liberação",
        prereservaLiberada: "Preservar liberado",
        seLiberoPrereserva: "A pré-reserva foi liberada corretamente.",
        motivo: "Motivo",
        campoRequerido: "Este campo é obrigatório",
        cerrar: "Fechar",
        cancelar: "Cancelar",
        liberar: "Liberar",
        ventasVacio: "No momento, você não realizou nenhuma reserva.",
        todos: "Todos",
        soloDeudores: "Devedores",
        soloNoDeudores: "Não Devedores",
    },
    detallePagoPage: {
        errorMontoValido: "Você deve inserir um valor válido para continuar.",
        errorMontoCero: "O valor a ser pago não pode ser zero.",
        errorMontoIncorrecto: "O valor a ser pago está incorreto.",
        errorMontoMayorDeuda:
            "O valor a ser pago não pode ser maior que o débito total.",
        errorFormatoMonto: "O formato do valor a pagar está incorreto.",
        mensajeZurielDeshabilitado:
            "Estamos trabalhando para fornecer a você os melhores serviços, novos recursos estarão disponíveis em breve.",
        detalleProductos: "Detalhes do produto",
        resumenPago: "RESUMO DO PAGAMENTO",
        agregarCuotas: "ADICIONE TAXAS",
        detallePago: "DETALHE DO PAGAMENTO",
        pagar: "PAGAR",
        generarContrato: "GERAR CONTRATO",
        seleccionarCuotas: "Selecionar as parcelas a adicionar",
        cuotaVenceFecha: "COTA #{{nroCuota}}, DATA DE VENCIMENTO:",
        cancelar: "Cancelar",
        agregar: "Adicionar",
        precio: "Preço",
        pago: "Pagamento",
        debe: "Débito",
        cuotasPendientes: "Parcelas pendentes",
        vencidas: "Vencimento",
        mensual: "Mensal",
        plazo: "Prazo",
        meses: "meses",
        fechaVencimiento: "Data Vencimento",
        ingreseMontoPagar: "Digite o valor a pagar",
        inicial: "INICIAL",
        cuotaInicial: "Taxa inicial",
        subTotal: "SUBTOTAL",
        pagosACuenta: "PAGAMENTOS NA CONTA",
        totalPagar: "TOTAL A PAGAR",
        pagado: "PAGO",
        detalleCuotas: "Detalhe das prestações",
        nro: "Nº",
        vence: "Vencimento",
        capital: "Capital",
        interes: "Juros",
        formulario: "Formulário",
        expensas: "Despesas",
        multas: "Multas",
        importeCuota: "Valor da quota",
        pagoCuenta: "Pagamento por conta",
        total: "Total",
        nroCuota: "Nro. Compartilhar",
        detalles: "Detalhes",
        contado: "Contado",
        incial: "Inicial",
        mensual2: "Por mês",
    },
    realizarPagoPage: {
        mensaje3ds:
            "Prezado cliente, por questões de segurança é possível que seja feita uma transação para validar seu cartão, esta ação não acarretará nenhum custo.",
        mensajePago: "Pague com seus pontos diretamente do aplicativo Sion Pay.",
        realizarPago: "Efetuar o pagamento",
        pagoCuotas: "Pagamento das taxas",
        pagoPrereserva: "Pagamento da pré-reserva",
        pagoReserva: "Pagamento da reserva",
        pagoProcesado: "Pagamento processado corretamente",
        pagoExitosoPrereserva:
            "Você acabou de efetuar o pagamento da sua pré-reserva corretamente",
        pagoExitosoCuotas:
            "Você acabou de fazer o pagamento das suas taxas corretamente",
        pagoExitosoCuota:
            "Você acabou de fazer o pagamento de suas taxas corretamente",
        pagoExitosoReserva:
            "Você acabou de fazer o pagamento de sua reserva corretamente",
        volver: "Voltar",
        terminosCondiciones: "TERMOS E CONDIÇÕES",
        metodoPago: "FORMA DE PAGAMENTO",
        datosPago: "INFORMAÇÕES DE PAGAMENTO",
        finalizar: "FINALIZAR",
        codigoTarjetaRequerido: "O campo Código é obrigatório",
        codigoTarjetaInvalido: "O código inserido é inválido",
        tarjetaPrepago: "Cartão pré-pago",
        proyecto: "Projeto",
        producto: "Produto",
        montoPagar: "Valor a pagar",
        indicaciones: "Instruções",
        indicacionCompra:
            "Insira os códigos um por um, pressionando Carregar até atingir o saldo a pagar.",
        indicacionPrereservaReserva:
            "Insira o código do seu cartão e pressione Processar para continuar.",
        cargar: "Carregar",
        codigo: "Código",
        codigoTarjeta: "Código do Cartão",
        montoTarjeta: "Cartão Valor",
        montoAplicar: "Aplicar Valor",
        verCuotas: "Ver Taxas",
        atras: "Voltar",
        procesar: "Processo",
        errorTarjetaRequerida: "O campo do cartão é obrigatório",
        errorVisaMastercard: "Somente cartão Visa ou MasterCard é permitido",
        errorTarjetaIncompleta:
            "Você deve preencher o número do cartão para continuar",
        errorTarjetaIncorrecta: "Você deve preencher o campo Cartão corretamente",
        errorExpiracionRequerido: "O campo Validade é obrigatório",
        errorExpiracionIncorrecto:
            "Você deve preencher a Validade campo corretamente",
        errorMesExpiracion: "O mês inserido no campo Vencimento está incorreto",
        errorAnhioExpiracion: "O ano inserido no campo Vencimento está incorreto",
        errorMesAnhioExpiracion:
            "O mês e o ano inseridos no campo Vencimento estão incorretos",
        errorCvcRequerido: "O campo CVC é obrigatório",
        errorCvcIncorrecto: "Você deve preencher o campo CVC corretamente",
        numeroTarjeta: "Número do cartão",
        expiracion: "Validade",
        cuentaSionPayNoCreada: "Sua conta SION Pay não foi criada.",
        pagoSionPay: "Pagamento com SionPay",
        cuenta: "Conta",
        estadoCuenta: "Status da conta",
        puntosDisponibles: "Pontos disponíveis",
        descuento: "Desconto",
        totalPagar: "Total a pagar",
        ingresarCodigo:
            "Digite seu código PIN gerado a partir de sua conta Sion Pay",
        codigoPin: "Código PIN",
        tituloPagoSionPay:
            "Informações de pagamento - Pagamentos com pontos SionPay",
        solicitarPagoAmigo: "Solicite a um amigo para pagar a taxa",
        generarCodigo: "GERAR CÓDIGO",
        generarCodigoMensaje:
            "Gere um código para o seu amigo fazer o pagamento a partir do aplicativo",
        copiar: "COPIAR",
        volverAPagar: "Volte para Pagar",
        copiadoPortapapeles: "Copiado para a área de transferência com sucesso.",
        habilitado: "Ativado",
        datosDeSuCuenta: "Detalhes da sua conta",
        tituloAlertTarjeta: "Lista de cartões",
        tituloInfoTarjeta: "Lista de cartões",
        mensajeAdvertenciaTarjeta:
            "Prezado cliente, você excedeu o limite de cartões permitidos no Sion Pay ou Sion Office. Entre em contato com o atendimento ao cliente para prosseguir com a autorização.",
        terminoCondiciones: "Termos e Condições",
        aceptar: "Aceitar",
        aceptarTerminos: "Eu aceito os termos e condições",
        deshabilitarPagoTarjetaMensaje:
            "Você também pode fazer o seu pagamento por: JANELAS SION, DEPÓSITOS E / OU TRANSFERÊNCIAS, SION PAY",
        nuevaTarjeta: "Novo cartão",
        sinTarjetas: "Você não possui cartões cadastrados.",
        vencida: "Atrasado",
        habilitada: "Habilitado",
        noHabilitada: "Não habilitado",
        eliminar: "Eliminar",
        seleccionar: "Selecione",
        habilitar: "Habilitar",
        habilitarTarjeta: "Ativar cartão",
        cancelar: "Cancelar",
        eliminarTarjeta: "Remover cartão",
        mensajeEliminarTarjeta:
            "Tem certeza de que deseja excluir o cartão {{card}}?",
        pagar: "Pagar",
        detallePago: "Detalhes de pagamento",
        prereserva: "PRÉ RESERVA",
        reserva: "RESERVA",
        compra: "DÍVIDAS",
        codigoCvc: "Código de verificação (CVC)",
        upgradeMembresia: "ATUALIZAÇÃO DE ASSOCIAÇÃO",
        tituloNoPosible: "O pagamento por cartão não está disponível",
        mensajeNoPosible: "Para valores acima de {{monto}}, o pagamento com cartão não está disponível.",
        pagoQR: "Efetuar pagamento por QR",
        criptoactivo: "Como cliente do Grupo SION e em cumprimento à regulamentação vigente, declaro que os recursos desta operação não têm origem, razão ou destino em operações de compra ou venda de criptoativos.",

    },
    generarContratoPage: {
        generarContrato: "Gerar contrato",
        datosPersonales: "DADOS PESSOAIS",
        tituloContrato: "TÍTULO DO CONTRATO",
        planPagos: "PLANO DE PAGAMENTO",
        campoRequerido: "Este campo é obrigatório",
        emailIncorrecto: "O campo Email tem um formato incorreto",
        docIdentidad: "Doc. Identidade",
        nit: "Nit",
        nacionalidad: "Nacionalidade",
        documentoExpedido: "Documento emitido",
        fechaNacimiento: "Data de nascimento",
        primerNombre: "Nome",
        segundoNombre: "Nome do meio",
        apellidoPaterno: "Sobrenome paterno",
        apellidoMaterno: "Sobrenome materno",
        direccion: "Endereço",
        paisResidencia: "País de residência",
        ciudadResidencia: "Cidade de residência",
        genero: "Sexo",
        telefono: "Telefone",
        celular: "Celular",
        correo: "Correio",
        referido: "Referência",
        siguiente: "SEGUINTE",
        atras: "VOLTAR",
        fechaInicio: "Data de início",
        fechaPago: "Data de pagamento",
        nro: "Nro",
        saldoCapital: "Saldo de capital",
        cuota: "Taxa",
        formulario: "Formulário",
        saldo: "Saldo",
        importeCuota: "Valor da cota",
        expensa: "Despesa",
        saldoExpensa: "Saldo de despesas",
        aceptarContrato: "Aceitar Contrato",
    },
    formPrereservaPage: {
        formularioPrereserva: "Formulário de pré-reserva",
        errorPrereservar: "Você não pode fazer uma pré-reserva",
        errorRegistrarPrereserva:
            "Erro ao registrar a pré-reserva, revise os dados",
        datosDelProducto: "Dados do produto",
        cuotaInicial: "Taxa inicial",
        producto: "Produto",
        datosDelCliente: "Dados do cliente",
        documentoIdentidad: "Documento de identidade",
        nacionalidad: "Nacionalidade",
        expedidoEn: "Emitido em",
        extranjero: "ESTRANGEIRO",
        fechaNacimiento: "Data de nascimento",
        nombre1: "Nombre 1",
        nombre2: "Nombre 2",
        apellidoPaterno: "Sobrenome paterno",
        apellidoMaterno: "Sobrenome materno",
        paisResidencia: "País de residência",
        ciudadResidencia: "Cidade de residência",
        genero: "Sexo",
        masculino: "Masculino",
        femenino: "Feminino",
        telefono: "Telefone",
        celular: "Celular",
        correo: "Email",
        direccionCliente: "Endereço do cliente",
        direccion: "Endereço",
        cancelar: "Cancelar",
        reservar: "Reservar",
        advertencia: "AVISO",
        prereservaLote: "Pré-reservar terreno",
        prereservaMembresia: "Pré-reserva de associações",
        prereservaCasa: "Pré-reserva de Casa",
        mensajePrereservaCasa: "A pré-reserva foi registrada corretamente",
        nombre1Requerido: "Nome 1 obrigatório",
        primerNombreRequerido: "Primeiro nome necessário",
        apPaternoVacio:
            "Este não-usuário não tem o sobrenome registrado, atualize seus dados",
        appPaternoMaternoRequerido: "Sobrenome paterno ou materno obrigatório",
        direccionRequerido: "O endereço é obrigatório",
        direccionNumerosLetras: "O campo Endereço permite apenas números e letras.",
        celularRequerido: "O telefone celular é obrigatório",
        telefonoRequerido: "O telefone é obrigatório",
        soloNumeros: "Somente números são permitidos",
        minimoDigitos: "Em pelo menos é necessário {{nroDigitos}} digitos",
        errorSinCorreo: "Você não tem e-mail atribuído, atualize seus dados",
        correoRequerido: "E-mail obrigatório",
        correoInvalido: "Formato de e-mail inválido",
        ciudadResidenciaRequerido: "A cidade de residência é obrigatória",
        nacionalidadRequerido: "A nacionalidade é obrigatória",
        fechaNacimientoRequerido: "A data de nascimento é obrigatória",
        generoRequerido: "O sexo é obrigatório",
        paisResidenciaRequerido: "O país de residência é obrigatório",
        ciRequerido: "O IC emitido é obrigatório",
        idTipoDocIdentidadRequerido: "O tipo de documento é obrigatório",
        fechaNacimientoMayorEdad: "A pré-reserva é apenas para maiores de 18 anos.",
        tipoDocumento: "Tipo de documento",
        mensajePaisRectrincion :"Para {{pais}} este produto está disponível apenas no SION OFFICE",
    },
    cambiarPasswordPage: {
        cambiarPassword: "Alterar senha",
        reglasPasswordTitulo: "A senha deve atender às seguintes características.",
        reglaMinimo: "Mínimo 8 caracteres",
        reglaMayusculas: "Pelo menos uma maiúscula e minúscula",
        reglaCaracteresEspeciales: "Um caractere especial",
        reglaRepetirPassword: "Confirmar senha deve ser igual à nova senha",
        errorPassword: "A senha não atende os critérios descritos acima",
        anteriorPassword: "Senha antiga",
        nuevoPassword: "Nova senha",
        confirmarPassword: "Confirmar senha",
        errorConfirmarPassword: "Confirmar senha deve ser igual à nova senha",
        cambiar: "Alterar",
        tituloModal: "NOVA SENHA",
        mensajeModal:
            "A nova senha foi registrada com sucesso. Na próxima vez que você fizer login, deverá inserir sua nova senha.",
        deAcuerdo: "OK",
    },
    mapaPage: {
        errorCargarProyecto:
            "Ocorreu um problema ao carregar o projeto, tente mais tarde",
        tamanho: "Tamanho",
        mapaProyecto: "Mapa do projeto",
        mapaDeProyecto: "Mapa do projeto",
        dimension: "Dimensão",
        estadoTerrenos: "Estado do terreno",
        libre: "LIVRE",
        vendido: "VENDIDO",
        noFiltrado: "NÃO FILTRADO",
        cargarProyecto: "Carregar Projeto",
        caracteristicasProyecto: "Este projeto tem as seguintes características",
        leyenda: "Lenda",
    },
    mantenimientoPage: {
        enMantenimiento: "EM MANUTENÇÃO",
        volveremosEn: "VOLTAREMOS",
        dias: "Dias",
        horas: "Horas",
        minutos: "Minutos",
        segundos: "Segundos",
        volverAIntentar: "Tentar novamente",
        expiraEn: "Expira em",
    },
    reducer: {
        sinAccesoInternet: "Sem acesso à Internet",
        errorCargarDatos:
            "Erro ao carregar dados, tente novamente mais tarde, por favor",
        error444: "Erro 444 elio",
        sesionExpiro: "Sua sessão expirou",
        expiroPusher: "Eu expirou a sessão do pusher",
        expiroMovil: "Sua sessão expirou, válida apenas para celular",
        expiroApi: "Sessão expirada por meio da api",
        intenteloMasTarde: "Tente novamente mais tarde, por favor",
        cambiarIdiomaMsg:
            "A alteração do idioma foi bem-sucedida, faça login novamente para continuar.",
        cambioIdioma: "Mudança de idioma",
        registrarBroker: "Registrar Corretor",
        brokerLabel: "Corretor",
        brokerHelperText: "Digite a carteira de identidade ou o nome do corretor.",
        brokerRegistroExitoso: "O corretor foi registrado corretamente.",
        brokerDescripcion:
            "Corretor é a pessoa que colaborou na realização da venda. Você receberá um incentivo de acordo com as políticas da empresa.",
    },
    InfoTarjetaLinkser: {},
    MensajeConfirmacion: {
        botonAceptar: "De acordo.",
        tituloModal: "Atenção",
    },
    AlertTarjeta: {
        descripcionExcedida:
            "Detectamos que você já tem {{nroTarjetas}} cartões  registrados",
        checkDescripcion: "Pague com outro cartão que não esteja na lista.",
        botonConfirmacion: "Eu entendo",
        botonAtras: "Voltar",
    },
    modalProjectsPorFiltros: {
        tipoComplejoAbierto: "ABERTO",
        tipoComplejoCerrado: "FECHADO",
        realizarBusqueda: "Realize sua busca",
        seleccionarZona: "Selecione uma área",
        seleccionarTipoComplejo: "Seleccione um tipo de desenvolvimento",
        cuotaInicial: "Taxa inicial em $us",
        cuotaMensual: "Taxa mensal em $us",
        seleccionarComplejo: "Selecione seu resort",
        zona: "Área:",
        tipoComplejo: "Tipo complexo:",
        btnBuscar: "BUSCA",
        filtroCaracteristicas: "Por características",
        filtroComplejo: "Pelo nome",
        lotesLibres: "Lotes livres:",
    },
    prereservaMembresia: {
        meses: "meses",
        pagoInicialDe: "Pagamento inicial de",
        cuotaMensualDe: "Taxa mensal de",
        resumen: "RESUMO",
        pagoAlContado: "Pagamento em dinheiro",
        tituloModalVacation: "Deseja adicionar uma Associação de Kalomai Resort?",
        agregarMembresiaVacation: "Adicionar uma Associação de Kalomai Resort",
        seleccionarPlan: "Selecionar plano",
        eliminarTitulo: "Tem certeza?",
        eliminarMensaje:
            "Tem certeza que removeu esta assinatura de sua compra atual?",
        prereservasRealizadas: "Pré-reservas feitas",
        errorPrereserva: "Uma ou mais pré-reservas não puderam ser feitas",
        successPrereserva:
            "As pré-reservas dos membros selecionados foram registradas corretamente.",
        mes: "mês",
    },
    mejorarPage: {
        costoAdenda: "Custo de atualização",
        abonado: "Pago",
        verAdenda: "Veja o Adendo",
        conceptoPago: "Conceito de pagamento",
        actualizacionMembresia: "Atualização de associação",
        mejorarMembresia: "Melhorar Associação",
        modalOkTitulo: "Associação aprimorada com sucesso",
        modalOkMensaje:
            "Sua associação {{origen}} foi atualizada com sucesso. Agora você pode aproveitar sua nova associação: {{destino}}",
        modalWarnTitulo: "Associação aprimorada com sucesso",
        modalWarnMensaje:
            "A associação Kalomai foi aprimorada corretamente, por favor, vá até o Atendimento ao Cliente para preencher os dados necessários para o Adendo.",
        modalAdendaCheck:
            "Revisei corretamente meus dados pessoais no ADENDO e aceito que estão corretos.",
        modalAdendaMessage:
            "No caso de algum de seus dados pessoais estar incorreto, entre em contato com o atendimento ao cliente.",
        adenda: "Adendo",
        aceptarAdenda: "Aceitar Adendo",
        mensajeOro: "Seu plano de pagamento não sofrerá modificações.",
        saldoPagar: "Saldo a Pagar",
        aceptarDocumentos: "Aceitar Documentos",
        renuncia: "ACEITAÇÃO DE CONDIÇÕES",
        documentos: "DOCUMENTOS",
        modalRenunciaCheck:
            "Concordo em ACEITAR AS CONDIÇÕES, também confirmo que meus dados pessoais estão corretos.",
        verDocumentos: "VER DOCUMENTOS",
    },
    rankingPage: {
        puntos: "Pontos: ",
        ventasUno: "¡Você tem ",
        ventasDos: " venda(s), ",
        ventasTres: " venda(s) para fazer.!",
        ventasTotal: " vendas !",
        sinVentaFuera: "¡Não fique de fora!",
        sinVentaCarrera:
            "Com apenas 1 venda, você estará na corrida por seus pontos.",
        veneficiario:
            "Os 40 mais vendidos do semestre que acumularem mais pontos serão beneficiados.",
        notasAclaratorias: "NOTAS EXPLICATIVAS",
        notaUno:
            "Se o ACI for antigo, deve fazer pelo menos 1 venda, desde o início da carreira até o final do semestre.",
        notaDos:
            "Nota: Considera-se ACI antigo aquela pessoa que realizou uma ou mais vendas até um dia antes do início da prova.",
        notaTres:
            "Se o ACI for novo, ele entra na carreira a partir do momento que faz sua primeira venda, a partir desse mês ele deve estar ativo e vender todo mês até o final do semestre",
        notaCuatro:
            "Nota: Considera-se novo ACI aquela pessoa que realiza sua primeira venda no semestre em que iniciou a carreira.",
        notaCinco:
            "Se o ACI novo ou antigo parar de vender um mês, no mês seguinte ele deve fazer duas vendas para permanecer na corrida, caso contrário ele está fora dela. Nota: esta opção só pode ser usada uma vez, ou seja, se o ACI deixar de vender pela segunda vez em um mês consecutivo, ele fica automaticamente fora da corrida.",
        notaSeis:
            "O ACI vencedor deverá ser válido nas parcelas mensais de todos os seus produtos pessoais até o momento da viagem.",
        notaSiete:
            "Os produtos das vendas, que tornam o credor do bônus de viagem ACI, devem estar em dia com as 2 primeiras parcelas mensais para garantir sua pontuação. Caso contrário, a venda não será considerada para o acúmulo de pontos de corrida.",
        notaOcho:
            "As parcelas mensais dos produtos que foram vendidos fora do período da corrida não somam pontos para o bônus de viagem.",
        notaNueve:
            "Todas as mensalidades pagas (somente capital), até a data da última revisão, geram pontos.",
        notaDiez:
            "Até a data final da revisão, as amortizações efetuadas gerarão pontos.",
        notaOnce:
            "Serão realizadas duas corridas por ano para este bônus, elas serão contempladas da seguinte forma:",
        notaDoce:
            "Se uma das vendas que somarem pontos para a corrida fizer uma Mudança de Patrocínio ou Cessão de direito, todos os pontos dessa venda não serão levados em consideração.",
        notaTrece:
            "Se uma das vendas realizadas no semestre de cada corrida for revertida antes da data indicada da última revisão, ela não será considerada para a pontuação do bônus de viagem, mas o ACI é considerado ativo naquele mês na revisão do bônus de viagem.",
        notaCatorce:
            "Si el ACI está con código inactivo, bloqueado o con proceso de ética al momento de realizar el viaje, perderá el Bono de Viaje.",
        notaQuince: "Cada $1 pago equivale a 1 ponto.",
        notaDieciseis:
            "O pagamento de multas e sobretaxas NÃO gera pontos para a corrida.",
        notaDieciSiete:
            "As vendas à vista somam pontos, como se fosse uma venda parcelada com 10% de taxa inicial e duas mensalidades mínimas pagas.",
        notaSiesiOcho: "As autocompras são válidas.",
        notaDieciNueve:
            "O pacote e os roteiros da viagem dependerão do destino e da agência (excursões, jogos, lembranças, etc. são pagos pelo vencedor).",
        notaVeinte: "O voucher de viagem é INTRANSFERÍVEL.",
        thSemestre: "Semestre",
        thInicioCarrera: "início de carreira",
        thFinalCarrera: "Fim da corrida",
        thFechaUltimaRevision: "Data da última revisão",
        thMesViaje: "mês da viagem",
        porDefinir: "por definir",
        preCalificado: "Pré - qualificado",
    },
    contadorRegresivo: {
        quedan: "Restante:",
        dias: "Dia(s)",
        horas: "Hora(s)",
        minutos: "Minutos(s)",
        segundos: "Segundo(s)",
    },
    portadaRanking: {
        ganaPuntos: "¡Ganhe pontos em todas as suas vendas!",
        desde: "De",
        al: "a",
    },
    eventos: {
        validarNombreLetras: "O campo Nome completo deve conter apenas letras.",
        validacionIdEventoRequerido: "O campo Evento é obrigatório",
        validacionNombreInvitadoRequerido: "O campo Nome Completo é obrigatório.",
        validacionNombreInvitadoMax:
            "O campo Nome Completo não pode ter mais de 255 caracteres.",
        validacionCiInvitadoRequerido: "O campo ID é obrigatório.",
        valicacionCiInvitadoMax: "O campo ID não pode ter mais de 40 caracteres.",
        validacionFechaNacimiento: " O campo Data de Nascimento está incorreto.",
        validacionFechaNacimientoRequerido:
            "O campo Data de Nascimento é obrigatório.",
        invitadoRegistrado: "O convidado foi registrado com sucesso.",
        listaInvitadosVacia: "Não há eventos habilitados no momento.",
        evento: "Evento",
        todos: "TODOS",
        agregar: "Adicionar convidado",
        nombre: "Nome",
        carnet: "ID",
        fechaNacimiento: "Data de nascimento",
        registrarInvitado: "Registrar convidado",
        seleccioneEvento: "SELECIONE UM EVENTO",
        nombreCompleto: "Nome completo",
        cancelar: "Cancelar",
        registrar: "Registrar",
    },
    abonos: {
        errorStock:
            "No momento não há estoque disponível para o Bilhete de Temporada selecionado.",
        guerreroRoyalPari: "GUERRERO ROYAL PARI",
        abono: "ABONO",
        precios: "Preços",
        beneficios: "Benefícios",
        familiar: "FAMILIAR",
        guerrero: "SUPER GUERRERO",
        especial: "ESPECIAL",
        infantil: "INFANTIL",
        mensajeAlertPrereserva: "Selecione um preço para fazer a pré-reserva.",
        tituloModal: "Pré-reserva de assinatura",
        mensajeModal: "A pré-reserva foi feita corretamente",
        beneficio1: "Pertence ao grupo de parceiros do WhatsApp.",
        beneficio2: "Descontos em passagens e viagens com a equipe.",
        beneficio3: "Kits de boas-vindas.",
        beneficio4: "Descontos em nossos produtos de merchandising.",
        beneficio5: "Experiência profissional com a equipe.",
        beneficio6: "Ingresso nos treinamentos da equipe.",
        beneficio7: "Desconto de 10% no SPA para a família na Clínica Urbarí.",
        beneficio8: "Desconto de 5% na internação na Clínica Urbarí.",
        beneficio9:
            "Entrada no estádio quando estivermos presentes para todos os jogos oficiais do div. copa profissional ou sul-americana.",
        beneficio10: "10 passes para Kalomai Park por ano.",
        beneficio11: "5 passes para Kalomai Park por ano.",
        contado: "CONTADO",
        credito: "CRÉDITO",
        nroPrereserva: "O seu número de pré-reserva é:",
    },
    ticketsPage: {
        tickets: "Ingressos",
        cupon: "CUPONS",
        tituloPrincipal: "MEUS INGRESSOS",
        ticketscupon: "Cupons",
        tituloPrincipalcupon: "Meus cupons",
        tituloPaginaSb1: "PROMOÇÃO DE PAGAMENTO NA HORA.",
        tituloPaginaSb2:
            "Pague em dia a taxa do terreno do mês de outubro de 2023 e você receberá 2 ingressos.",
        tituloPaginaSb1cp: "PROMOÇÃO DE PAGAMENTO NA HORA.",
        tituloPaginaSb2cp:
            "Pague sua taxa de adesão em dia e receba um ticket de consumo Virtual para uso no Kalomai.",
        fechaini: "válido de: ",
        fechafin: "valido ate: ",
        valido: "VÁLIDO",
        expirado: "EXPIRADO ",
        agotado: "EXAUSTA",
        pendiente: "PENDENTE",
        informacion: "NOTA: ",
        informacion2:
            "Para entrar no parque KALOMAI você deve apresentar o Bilhete.",
        informacion2cupon: "Os vouchers devem ser resgatados no parque",
    },
    updateDataUser: {
        titulo: "Atualização de dados",
        subTitulo: "Por favor, atualize as seguintes informações são necessárias",
        labelCorreo: "E-mail",
        errorCorreo: "O e-mail não é válido",
        correoRequerido: "O e-mail é obrigatório",
        labelPais: "País de residência",
        textItemPais: "Selecione seu país de residência",
        seleccionarPais: "Você deve selecionar seu país de residência",
        labelCiudad: "Cidade de residência",
        textItemCiudad: "Selecione cidade de residência",
        seleccionarCiudad: "Você deve selecionar sua cidade de residência",
        labelCodigoPais: "Código país",
        idioma: "pt",
        labelTelefono: "Celular",
        telefonoNoNumero: "O número de celular deve ser apenas números",
        telefonoRequerido: "O número de celular é obrigatório",
        telefonoMinDigitos:
            "O celular não deve ter menos de {{minDigitos}} dígitos",
        telefonoMaxDigitos: "O celular não deve ter mais de {{maxDigitos}} dígitos",
        btnActualizar: "Atualizar dados",
        textActualizacionCorrecta: "Seus dados foram atualizados com sucesso",
        btnAceptar: "Aceitar",
        textLoader: "Atualizando dados",
    },
    consultaCiDialog: {
        textYo: "Eu",
        textHacerReserva: "Farei uma reserva para",
    },
    datosFactura: {
        titulo: "Dados para a fatura",
        msgInformativo:
            "Verifique se os seus dados estão correctos para evitar reclamações futuras.",
        labelDocumento: "Número do documento",
        errorDocumentoNoNumero: "O tipo de documento aceita somente números",
        documentoMinDigitos: "Número mínimo do documento {{minDigitos}} caracteres",
        documentoMaxDigitos: "Número máximo do documento {{maxDigitos}} caracteres",
        errorDocumento: "O número do documento é obrigatório",
        labelComplemento: "Complemento",
        errorCompAlfaNum: "Erro, p. ex: 1A",
        errorComplemento: "Max. {{maxDigitos}} caract",
        labelTipoDocumento: "Tipo de documento",
        textItemTipoDoc: "Selecione o tipo de documento",
        errorTipoDocumento: "Você deve selecionar o tipo de documento",
        labelRazonSocial: "Nome da empresa",
        razonSocialRequerido: "O nome da empresa é obrigatório ",
        labelCorreo: "E-mail",
        errorCorreo: "O e-mail não é válido",
        correoRequerido: "O e-mail é obrigatório",
        labelTelefono: "Celular",
        telefonoNoNumero: "O celular deve ser apenas números",
        telefonoRequerido: "O número do celular é obrigatório",
        telefonoMinDigitos:
            "O celular não deve ter menos de {{minDigitos}} dígitos",
        telefonoMaxDigitos: "O celular não deve ter mais de {{maxDigitos}} dígitos",
        btnTextEnviar: "Salvar dados",
        textBtnVerDatos: "Ver meus dados para a fatura",
        textBtnCancelar: "Cancelar",
        textDatosRegistrados: "Seus dados foram registrados corretamente",
        btnAceptar: "Aceitar",
    },
    simpleQR: {
        titulo: "QR Simples",
        textDescargar: "Baixar",
        textCompartir: "Compartilhar",
        descripcion: "Para ver outras formas de pagamento clique no botão a seguir",
        textFormasPago: "Outras formas de pagamento",
        pagoQR: "Pagamento QR simples",
        errorQR:
            "O QR não pôde ser gerado, você pode ver outras formas de pagamento",
        pagoConfirmado: "Seu pagamento por QR foi cadastrado corretamente",
        btnAceptar: "Aceitar",
        textErrorAlRegistrar:
            "O pagamento de QR foi confirmado, está em processo de regularização",
        nroTransaccion: "Nº da transação:",
        fechaVencimiento: "Data de vencimento:",
        monto: "Quantia:",
        conceptoPago: "Conceito: Pagamento",
        tituloQR: "PAGAR POR QR",
        noGenerado: "Não foi possível gerar o código QR",
        reintentar: "Tente novamente",
        cerrar: "Fechar QR",
    },
    carnetKalomaiPage: {
        tituloAlertSinKarnet: "CREDENCIAMENTO KALOMAI",
        cuerpoAlertSinKarnet:
            "Se você é membro Kalomai, entre em contato com o serviço de atendimento ao cliente para regularizar sua ACCREDITAÇÃO KALOMAI.",
    },
    membresiasCabanasPage: {
        titulo: "Minhas associações",
        subtitulo: "Selecione a associação com a qual você acessará a reserva de sua cabine.",
        mensajeEnMora: "Prezado cliente, você está com parcelas em atraso, fique em dia para ter acesso aos benefícios de reservar uma cabine.",
        mensajeMembresiasVacias: "Você não tem associações para reservar uma cabine.",
        mensajeNoTieneCarnetVigente: "Prezado cliente, você não possui um documento de identidade válido, entre em contato com o atendimento ao cliente para atualizar suas informações.",
        labelProducto: "Produto: "
    },
    portadaBonoMejorVendedor: {
        ganaPuntos: "¡Bônus de Mais Vendido!",
        desde: "Do",
        al: "para",
        validezCicloBono: "De 18 de fevereiro a 31 de julho de 2023",
    },
    bonoMejorVendedorPage: {
        puntos: "Pontos: ",
        ventasUno: "¡Você tem",
        ventasDos: "venda(s), você está perdendo",
        ventasTres: "mais!",
        ventasTotal: " vendas !",
        sinVentaFuera: "Não fique de fora!",
        sinVentaCarrera:
            "Concretize suas vendas, faça parte dos 30 melhores do mês e ganhe o BÔNUS de $300",
        veneficiario:
            "Todos os consultores de negócios independentes que atingirem a meta no valor das vendas de membros e/ou terrenos incluídos nas seguintes datas serão beneficiários:",
        politicas: "POLÍTICAS DA FORMA DE GANHAR BÔNUS BEST SELLERS",
        mejoresVendedores:
            "Os 30 maiores vendedores em valor a cada mês receberão US$ 300 em dinheiro",
        notasAclaratorias: "Notas explicativas:",
        notaUno:
            "Para ter acesso ao bônus, o ACI terá que estar entre os mais vendidos em quantidade do mês.",
        notaDos:
            "O consultor deve estar atualizado sobre seus produtos pessoais para receber o bônus.",
        notaTres: "Apenas vendas pessoais são válidas.",
        notaCuatro:
            "Só serão consideradas as vendas com contrato gerado até o final de cada mês.",
        notaCinco:
            "O bônus será entregue todo mês juntamente com o pagamento das comissões.",
        notaSeis: "Válidas as vendas a prazo e à vista.",
        notaSiete:
            "Para receber o bônus, o ACI deve faturar, caso contrário, será feita a retenção legal correspondente.",
        notaOcho:
            "EA lista final está sujeita a verificação e políticas da empresa.",
        thSemestre: "Semestre",
        thInicioCarrera: "Iniciar carreira",
        thFinalCarrera: "Fim da carreira",
        thFechaUltimaRevision: "Data da última revisão",
        thMesViaje: "Mês da viagem",
        porDefinir: "a ser definido",
        posicionPreliminar: "Sua posição atual",
        condicionesGenerales: "CONDIÇÕES GERAIS",
        condicionGeneralUno:
            "Só serão consideradas para a campanha as vendas com contrato gerado nas datas estabelecidas para o incentivo.",
        condicionGeneralDos:
            "Os ACIs ganhadores do incentivo não devem ter código inativo, bloqueado, expulso ou ético no momento do recebimento do incentivo, sob pena de perdê-lo.",
        condicionGeneralTres:
            "Se uma das vendas dos clientes da campanha de incentivo tiver transferência de direitos, mudança de patrocinador, reagendamento, reagendamento, rescisão de contrato ou transferência de capital, não contará para o bônus.",
        monto: "Montante",
        posicionActual: "SUA POSIÇÃO ATUAL É:",
        tienes: "¡VOCÊ TEM",
        enVenta: "$us. À VENDA!",
        debesEstar: "VOCÊ DEVE ESTAR NO TOP 30 PARA RECEBER O BÔNUS",
    },
    upgradeTravel: {
        precioTotal: "Preço total",
        totalPagado: "Total pago",
        totalDeuda: "Dívida total",
        precio: "Preço",
        descuento: "Desconto",
        precioFinal: "Preço Final",
        cuotaInicial: "Parcela Inicial",
        saldo: "Saldo",
        totalFinanciar: "Total a Financiar",
        cuotaMensual: "Prestação Mensal",
        plazo: "Prazo",
        simularAdenda: "Simular Adendo",
        btnAtras: "Voltar",
        btnPagarInicial: "Pagar entrada",
        agotado: "ESGOTADO",
        textMensaje: "Fique de olho em novos lotes",
        btnVolverAtras: "Voltar para meus produtos",
        txtPagoRealizado: "Seu pagamento foi bem-sucedido.",
        txtVerNuevoProducto: "Agora você pode visualizar seu novo produto.",
        btnMisProductos: "Meus produtos",
        detalleUpgrade: "DETALHES UPGRADE",
        pagarUpgrade: "PAGAMENTO UPGRADE",
        labelPorcentaje: "Porcentagem inicial",
        textAlContado: "Em dinheiro",
        btnAlContado: "Pagamento em dinheiro",
        textMeses: "Meses",
        tituloModalTravel: "ATUALIZAR ASSOCIAÇÃO DE VIAGEM",
        verTravel: "Upgrade de Viagem",
    },
    beneficioTravel: {
        btnActivarBeneficio: "Ativar benefício",
        precio: "Preço",
        pagado: "Pago",
        deuda: "Dívida",
        cuotasVencidas: "Prestações devidas",
        beneficios: "Benefícios",
        btnVerBeneficio: "Ver as minhas prestações",
        tituloBeneficio: "Benefícios disponíveis",
        btnAdquirir: "Adquirir benefício",
        noHabilitadoTitulo: "Nem todos os benefícios estão habilitados",
        noHabilitadoMensaje: "Para se qualificar para o benefício, você deve ter pago {{porcentaje}}% do seu produto e não ter dívidas pendentes.",
        cerrar: "Fechar",
        tituloMiBeneficio: "As minhas prestações adquiridas",
        detalle: "Detalhe do benefício",
        codigo: "Código RCI",
        fechaVencimiento: "Data de expiração",
        mensajeConfirmacion: "Pode agora ver o seu novo benefício",
        cerrarPago: "Fechar pagamentos",
        pagoBeneficio: "Pagamento de benefícios",
        tipoPago: "Tipo de pagamento",
        verMas:'Veja mais',
        verMenos: 'Ver menos',
    },
    calcularRecuperacion: {
        textoMenu: "Calcular recuperação",
        calculadora: "Calculadora de recuperação",
        textPlaceholder: "Pesquisa por C.I.",
        nombre: "Nome",
        ci: "C.I.",
        nroVenta: "Nº de venda",
        producto: "Produto",
        fechaContrato: "Data do contrato",
        totalPagado: "Total pago",
        totalDeuda: "Total devido",
        montoReconocido: "Valor reconhecido",
        btnBuscarProyectos: "Projetos de pesquisa",
        proyectos: "Projetos",
        productos: "Produtos",
        btnCerrar: "Fechar",
        mensajeAlert: "Nenhum projeto encontrado",
        precioLista: "Preço de tabela",
        descuento: "Desconto",
        precioVenta: "Preço de venda",
        saldo: "Saldo para financiamento",
        restaurar: "Restaurar valores",
        sinReversiones: "Você não tem produtos revertidos",
        tituloDialogComparar: "Produto revertido / Novo produto",
    },
    pagoStripe: {
        tituloModal: 'Pagamento do beneficiário',
        procesando: 'Processamento ',
        pagar: 'Pagar',
        pagoMensaje: 'Seu pagamento de benefício foi registrado com sucesso.',
        pagofallido: 'Seu pagamento está sendo processado.',
        mailInvalido: 'Selecione um endereço de e-mail válido',
        mailConfirma: 'Confirmar e-mail',
        correoElectronico: 'Endereço de e-mail',
        mensajeCorreo: 'Você receberá as informações do seu pagamento no endereço de e-mail selecionado.',
        selectTextMail: 'Endereço de e-mail',
        selectMailVacio: 'Selecione um endereço de e-mail'
    },
    eventosACI: {
        etiquetaCrearPaso1: "Nome do reunião, cidade e praças",
        etiquetaCrearPaso2: "Data, hora e endereço",
        etiquetaCrearPaso3: "Anfitrião, palestrante e telefone",
        etiquetaCrearPaso4: "Palestrante",
        dirrecionTitulo: "Endereço do reunião.",
        nombreTitulo: "Nome do reunião.",
        ciudadTitulo: "Cidade.",
        nombreOrganizadorTitulo: "Nome do anfitrião.",
        celularOrganizadorTitulo: "Telefone do anfitrião",
        disertanteTitulo: "Nome do palestrante",
        fechaHora: "Data e hora",
        capacidadTitulo: "Vagas disponíveis",
        tituloDatos: "Dados gerais do reunião.",
        tituloDatosDirreccion: "Dados da equipe",
        tituloDatosOrganizador: "Dados da Dissertação",
        disertanteBuscar: "Pesquisar alto-falante",
        tituloBuscarCI: "Pesquisar",
        placeHolderDisertante: "Insira o ID do cliente",
        duracionLabel: "Duração em horas",
        dialogCreateTitle: "Criar reunião",
        dialogCreateOK: "Si, Criar reunião",
        dialogCreateCancel: "Não",
        dialogDisertanteTitle: "confirme o palestrante.",
        dialogDisertanteOk: "Sim, confirme o palestrante!",
        dialogInfoSearchNotFound: "Nenhum alto-falante encontrado",
        dialogInfoSearchAgain: "Por favor, faça uma nova pesquisa!",
        dialogErrorMessage: " Erro!",
        dialogInvitarOk: "Sim, convidar",
        dialogInvitarCancel: "Não, eu faço isso depois",
        dialogInvitarTitle: "Convidar pessoa",
        homeTituloMisEventos: "Minhas reuniões.",
        campoDireccion: "Endereço",
        campoCiudad: "Cidade",
        campoFechaHora: "Data e hora",
        campoDuraccion: "Duração",
        campoCapacidad: "Habilidade",
        campoOrganizador: "Anfitrião",
        campoDisertante: "Palestrante",
        campoTelefono: "Telefone",
        campoEmail: 'Correio',
        campoNombre: "Nome",
        campoInvitadoCI: "Número de identidade",
        tituloInvitados: "Lista convidados",
        tablaInvitadoNumero: "N",
        tablaInvitadoNombre: 'Nome',
        tablaInvitadoEmail: "Email",
        tablaInvitadoTelefono: "Telefone",
        tablaInvitadoFechaInvitacion: "Data do convite",
        tablaInvitadoPrePatrocinador: "Pre patrocinador",
        nuevoInvitado: "Convide um cliente em potencial",
        invitar: "Convidar",
        salaEventoTitulo: 'Salão do reuniões',
        salaAdministrar: "Mudar reunião",
        invitarTo: 'Convidar',
        eventos: 'Reuniões',
        organizarTitulo: 'Criar reunião',
        unirseSala: "Participe de um reunião",
        crearEvento: "Criar reunião",
        unirseEventoSubHeading: "Participe de um reunião perto de você. Primeiro selecione uma cidade",
        eventosNoRooms: "Não há eventos disponíveis nesta cidade.",
        proximosEventos: "Próximas reuniões:",
        unirmeEvento: "Junte-se a este  reunião",
        salaHeading: "Feche mais vendas participando em reuniões",
        unirmeEventoSucces: "Parabéns você se juntou ao reunião",
        crearEventoPropio: "Criar meu próprio reunião",
        unirmeto: "Juntar:",
        dialogUnirmeOk: "Sim, eu quero participar",
        dialogUnirmeCancel: "Não, depois eu faço",
        dialogJoinedSuccess: "Parabéns você se juntou ao reunião :",
        btnSala: "Ver Quarto",
        landingEventosHeading: "Reuniões de negócios.",
        landingEventosSubHeading: "Feche mais vendas participando de reunião. Crie seu próprio evento ou leve seus clientes em potencial para outro reunião.",
        langdingCrearHeading: "criei seu próprio reunião, convide outros ACIs, clientes em potencial e feche mais vendas",
        estadoEventoCancelado: 'Cancelado',
        estadoEventoFinalizado: 'Finalizado',
        estadoEventoEncurso: 'En curso',
        estadoEventoProgramado: 'Programado',
        salaAdministrarTituloFecha: "mudar Fecha, duraccion",
        salaAdministrarTituloDireccion: "mudar ciudad y direccion",
        salaAdministrarTituloGeneral: "Mudar titulo y capacidad",
        salaAdministrarTituloDisertante: "Mudar disertante",
        dialogModificar: "Sim, Mudar",
        dialogModificarTitleFrom: "Mudar titulo de",
        dialogModificarTo: "a",
        accionModificar: "Mudar",
        accionConjuccion: "y",
        dialogModificarCapacidadFrom: "Mudar capacidad de",
        dialogSinCambios: "He, Não has realizado ningun cambio!",
        ciudadActual: "Ciudad actual",
        dialogModificarCiudadFrom: "Mudar ciudad",
        dialogModificarDireccionFrom: "Mudar dirreccion",
        dialogModificarFechaFrom: "Mudar fecha",
        dialogModificarDuraccionFrom: "Mudar duraccion",
        dialogModificarDisertanteFrom: "Mudar disertante",
        dialogModificarEventoSuccess: "reunião modificado exitosamente!",
        dialogConfirmarDisertante: "Confirmar a",
        accionNo: "Não",
        accionCancel: "Sim, cancelar",
        btnCancelar: "Cancelar reunião",
        btnRetirar: "Retirar convite",
        btnCrearEvento: "Crear reunião",
        btnValidar: "Validar",
        btnRegresar: "Regresar",
        habilitarCancelar: "Habilitar cancelar",
        dialogCancelarTitulo: "Cancelar reunião",
        dialogCancelarSuccess: "reunião Cancelado",
        cargandoEvento: "Carregando dados do reunião",
        autoInvitacionError: "Não puedes invitarte a ti mismo",
        disertanteComoprospecto: "El disertante Não puede ser un prospecto",
        asdisertante: "Como orador?",
        asCapacidad: "com capacidade",
        volverInicio: "Volte ao início",
        presionaParaFinalizar: "Listo, presiona crear reunião para finalizar",
        verInvitados: "Ver lista de convidados",
        infoStaff: "Datos de los organizadores",
        creacionExitosa: "Reunião agendado com sucesso",
        historialHeading: "História",
        historialSubheading: "Sua história de criação, participação em reunião e história de convites",
        historialEventos: "história reunião",
        historialEventosSubheading: "Sua história de criação, participação em salas de reunião",
        historialInvitados: "Histórico de seus convites",
        historialInvitadosSubheading: "Histórico de seus convites para  em potencia",
        tablaInvitadoDias: "Dias passados",
        tablaInvitadoEstado: "Estado",
        estadoInvitacionAbierta: "Registrado",
        estadoInvitacionVencida: "Expirou",
        estadoInvitacionConvertida: "Convertida",
        estadoInvitacionRetirada: "Retirada",
        estadoInvitacionIndeterminado: "Indeterminado",
        estadoInvitaconNodefinido: "Não definido",
        administrarInvitacion: "Modificar convite",
        enviarInvitacion: "Invitar cliente em potencial",
        dialogModificarInvitacionOk: "Si, Modificar",
        dialogModificarProspecto: "Modificar cliente em potencial",
        dialogRetirarProspecto: "retirar cliente em potencial",
        checBoxRetirarInvitacion: "Ativar retirada de convite",
        dialogRetirarInvitacionOk: "Si, retirar invitación",
        dialogAbandonarSala: "Sair da sala",
        dialogAbandonarSalaWarning: "Se você sair da sala, todos os seus convites para leads nesta sala serão retirados",
        dialogAbandonarSalaAction: "Sim, saia da sala",
        dialogAbandonarSalaSuccess: "Você saiu da sala",
        salaBtnControlAsistencia: "Controle de atendimento",
        tituloPaginaControlAsistencia: "Controle de atendimento",
        paginaControlAsistenciaSubheading: "Verifique o atendimento da equipe, ACI e prospects",
        leyendaAsistencia: "As perspectivas de outro ACI serão visíveis 60 minutos antes do início do evento",
        btnConfirmarAsitencia: "Confirmar presença",
        btnNoAsistio: "Não, compareceu",
        dialogConfirmarAsistencia: "Confirmar assistência",
        dialogConfirmarAsistenciaSub: "Selecione um convite",
        tituloProspectoDatosContacto: "Detalhes de contato",
        textoAsistencia: "Atendimento",
        textoNoAsistencia: "Ele não compareceu",
        textoNoDefinado: "",
        prospectoContactoCorreo: "Correio",
        prospectoContactoDual: "Correio e telefone",
        prospectoContactoTelefono: "telefone",
        aclaracion2Disertante: "Pode ser o criador da sala ou outro ACI",
        btnVerificar: "Verificar",
        moderardorSala: "É Moderador de Sala",
        moderardorSalaSubHeading: "O moderador pode ajudar no controle de atendimento",
        moderadorDialogTitle: "Mudar permissões de moderador",
        moderadorDialogOk: "Mudar permissões",
        moderadorDialogContent: "Mudar permissões",
        moderadorDialogContentDone: "As permissões foram alteradas",
        btnOmitir: "Ignore esta etapa",
        TituloAdministrarSala: "Gerenciar quarto",
        segundoDisertante: 'Segundo Palestrante',
        eliminarSegundoDisertante: 'Remover segundo palestrante',
        mostrando: 'mostrando',
        de: "de",
        errorDireccionNula: "O endereço é nulo ou vazio",
        errorDireccionLongitudCorta: "O endereço é muito curto",
        errorDireccionLongitudLargo: "O endereço é muito longo",
        errorFechaFueraRango: "Data fora do intervalo",
        errorDuracionFueraRango: "Duração fora do intervalo",
        errorIngresaUnCIValido: "Digite um ci válido",
        errorDisertanteYOrganizador: "O palestrante e o organizador não podem ser a mesma pessoa",
        errorModeloEvento: "Selecciona una modelo de reunião",
        errorSeleccionCiudad: "Selecciona una ciudad por favor",
        warningCapacidad: "Capacidade de reunião deve estar entre 5 e 999",
        errorAgenda1Part1: "Você não pode agendar um reunião neste momento porque o palestrante já o agendou",
        errorAgenda1Part2: "com data",
        errorAgenda1Part3: "e duração",
        errorAgenda1Part4: ", selecione outro palestrante",
        errorAgenda2Part1: "Você não pode agendar um reunião neste momento porque o segundo palestrante já o agendou",
        errorAgenda2Part2: "com data",
        errorAgenda2Part3: "e duração",
        errorAgenda2Part4: ", selecione outro palestrante",
        errorNoACI: "Registro não pertence pertence a um ACI",
        errorProspectoCorreo: "Digite o e-mail do cliente em potencial",
        errorProspectoCelular: "Digite o celular do cliente em potencial",
        errorFormatoCelular: "O formato do telefone não é válido",
        errorAbandonoSala: "erro ao sair da sala",
        errorProspecto: "",
        datosProspecto: "Dados do convidado",
        errorFormatoCI: "O número do documento não é válido",
        errorFormatoCorreo: "O e-mail tem um formato inválido",
        errorRazonCancelar: "Porfavor ingresa un descargo para cancelar este evento",
        errorCalendarioCreador: "Você não pode agendar um evento neste momento porque você já agendou {{name}} com data {{fechaHora}} e duração {{duration}}",
        errorCalendarioDisertante: "O palestrante já tem um evento agendado {{name}} com data {{fechaHora}} e duração {{duration}}, selecione outro palestrante",
        errorCalendarioDisertante2: "O segundo palestrante já tem um evento agendado {{name}} com data {{fechaHora}} e duração {{duration}}, selecione outro palestrante",
        errorFormatoNombre: "O nome inserido não atende ao formato exigido. O nome deve ter mais de cinco caracteres e menos de 120",
        mensajeSalaAsignarDisertante: "Mudar novo palestrante {{nombre}}?",
        btnOk: "Ok",
        textoNuevoDisertante: "Novo palestrante",
        mensajeAsistenciaConfirmada: "Assistência confirmada",
        mensajeFaltaConfirmada: "Falha confirmada",
        mensajeModificacionPermisos: "As permissões para o ACI {{name}} foram modificadas",
        mensajeFiltrarNombre: "Digite um critério de pesquisa",
        mensajeRetirarProspecto: "{{name}} com id {{ci}} retirado com sucesso",
        mensajeModificarProspecto: "{{name}} com id {{ci}} modificado com sucesso",
        btnSiguiente: "Próximo",
        btnContinuar: "Continuar",
        successProspectoInvitacion: "prospect {{name}} com id {{ci}} registrado com sucesso",
        campoID: "CI",
        errorInicialFecha: "Reunião devem ser agendados com 12 horas de antecedência. selecione outra data",
        crearEventoDialogo: "Você gostaria de criar um reunião de negócios,¿{{nombre}} para consultores de negócios independentes (ACI) para {{capacidad}} convidados",
        documentoIDPlaceHolder: "Digite seu documento de identidade nacional",
        documentoIDLabel: "Identidade"
    },
    promocion2X1Septiembre: {
        selecciona2doProd: "Seleciona 2º Produto",
        complejo: "Complexo",
        producto: "Produto",
        manzano: "Maçãzeira",
        nroLote: "Nº. Lote",
        superficie: "Superfície",
        productoSeleccionado: "Produto selecionado",
        seleccionandoProducto: "Selecionando o produto:",
    },
    dadosUif: {
        outrosDados: "OUTROS DADOS",
        cidadeNascimento: "Cidade de Nascimento",
        profissao: "Profissão",
        atividadeComercial: "Atividade Comercial",
        funcionarioPublico: "Você é atualmente ou foi funcionário público nos últimos 5 años?",
        nomeInstituicaoPublica: "Nome da Instituição Pública",
        cargo: "Cargo",
        declaroInfoVerazFide: "Declaro como cliente que as informações pessoais fornecidas são precisas e confiáveis.",
    },
    rooms: {  
        noEligle: "Lamentamos, não cumpra todos os requisitos necessários para acessar esta funcionalidade.",
        nroSala : "Número do reunião",
        enlaceCopiar: "Copiar link do seu convite",
        enlaceCopiado : "¡Link copiado para portapapeles!",
        freelancers:    "Trabalhador autonomo",
        invitados : "Invitados",
        titulo     : "Número do quarto #{{id}}",
        tipoSala   : "Tipo de reunião",
        searchText : "Procura por nome",
    },
    cardPreReserva:{
        botonEnlace:'Link',
        tituloModalEnlace:'Você deseja gerar um link de pagamento de pré-reserva do produto.',
        linkPago:'GENERAR LINK DE PAGO',
        valido:'Valido ate',
        tituloModalEnlaceReserva:'Você deseja gerar um link de pagamento de reserva de produto!',
    },
    verificarPAgoFacil:{
        proyecto:'PROJETO',
        producto:'PRODUTOS',
        nro:'Não.',
        tipo:'Cara',
        monto:'Quantia',
        irInicio:'Ir à página principal'
    }
};