import { requestPostWhithHeaders } from "../service/request";
import * as Action from "../redux/actions/login/loginAction";
import * as Actions from "../redux/actions/message/messageActions";

export const PAYMENT_MODES = {
  validateCustomerAuthentication: "VALIDATE_CONSUMER_AUTHENTICATION",
  consumerAuthentication: "CONSUMER_AUTHENTICATION",
};
export const OS_TYPES = {
  ANDROID: "Android",
  IOS: "iOS",
  OTRO: "Otro",
};

export const authType = {
  TIPO_USUARIO_PASSWORD: 1,
  TIPO_HUELLA: 2,
  TIPO_FACE_ID: 3,
};

export const generarYear = () => {
  var rangoAño = 10;
  var resultado = [];
  const fecha = new Date();
  var año = fecha.getFullYear();
  var n = 0;
  for (n = 0; n <= rangoAño; n++) {
    var Nyear = año + n;
    var datoAño = Nyear.toString();
    const object = { name: Nyear.toString(), value: datoAño.substr(2) };
    resultado = resultado.concat(object);
  }
  return resultado;
};
export const generarMeses = () => {
  const Meses = [
    {
      name: "Ene",
      value: "01",
    },
    {
      name: "Feb",
      value: "02",
    },
    {
      name: "Mar",
      value: "03",
    },
    {
      name: "Abr",
      value: "04",
    },
    {
      name: "May",
      value: "05",
    },
    {
      name: "Jun",
      value: "06",
    },
    {
      name: "Jul",
      value: "07",
    },
    {
      name: "Ago",
      value: "08",
    },
    {
      name: "Sep",
      value: "09",
    },
    {
      name: "Oct",
      value: "10",
    },
    {
      name: "Nov",
      value: "11",
    },
    {
      name: "Dic",
      value: "12",
    },
  ];
  return Meses;
};
export const copiarTextoToPapelera = (value) => {
  var textField = document.createElement("textarea");
  textField.innerText = value;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
};
export const sesionFallidaMovil = () => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        code: "SESSION_FAIL",
        message: "sesion fallida general",
      })
    );
    return;
  } else {
    console.log("habilitado solo en dispositivo movil token fallido");
  }
};
export const calcularPaginacion = (cantidad, paginacion) => {
  var numeros = cantidad / paginacion;
  var reciduo = cantidad % paginacion;
  if (reciduo != 0) {
    numeros = numeros + 1;
  }
  return numeros;
};

export const generarNroTransaccion = (
  empresaId,
  ventaId,
  verificador = null,
  nroCuotas = -1
) => {
  let nroTransaccion = "";
  let nroCuotaString = "";
  let idEmpresaStr = empresaId.toString();
  let idVentaStr = ventaId.toString();
  let codigoTransaccion = "12";
  while (idVentaStr.length < 10) {
    idVentaStr = "0" + idVentaStr;
  }

  while (idEmpresaStr.length < 2) {
    idEmpresaStr = "0" + idEmpresaStr;
  }

  if (verificador) {
    nroCuotaString = verificador.toString();
    while (nroCuotaString.length < 3) {
      nroCuotaString = "0" + nroCuotaString;
    }

    nroTransaccion = nroCuotaString + idVentaStr + idEmpresaStr;
    if (nroCuotas > 1) {
      nroTransaccion = "S" + nroTransaccion;
    }
  } else {
    while (codigoTransaccion.length < 3) {
      codigoTransaccion += "0";
    }
    nroTransaccion = codigoTransaccion + idVentaStr + idEmpresaStr;
  }
  return nroTransaccion;
};

export const generarNroTransaccionReservaCasa = (empresaId, ventaId) => {
  let nroTransaccion = "";
  let nroCuotaString = "";
  let idEmpresaStr = empresaId.toString();
  let idVentaStr = ventaId.toString();
  let codigoTransaccion = "12";
  while (idVentaStr.length < 10) {
    idVentaStr = "0" + idVentaStr;
  }

  while (idEmpresaStr.length < 2) {
    idEmpresaStr = "0" + idEmpresaStr;
  }

  while (codigoTransaccion.length < 3) {
    codigoTransaccion += "0";
  }
  return codigoTransaccion + idVentaStr + idEmpresaStr + new Date().getTime();
};

export const formatearNumero = (
  numero,
  cantidadDecimales = 2,
  separadorMiles = ".",
  separadorDecimales = ","
) => {
  numero = (numero + "").replace(/[^0-9+\-Ee.]/g, "");
  const n = !isFinite(+numero) ? 0 : +numero;
  const prec = !isFinite(+cantidadDecimales) ? 0 : Math.abs(cantidadDecimales);
  const sep = typeof separadorMiles === "undefined" ? "," : separadorMiles;
  const dec =
    typeof separadorDecimales === "undefined" ? "." : separadorDecimales;
  let s = "";
  const toFixedFix = function (n, prec) {
    if (("" + n).indexOf("e") === -1) {
      return +(Math.round(n + "e+" + prec) + "e-" + prec);
    } else {
      const arr = ("" + n).split("e");
      let sig = "";
      if (+arr[1] + prec > 0) {
        sig = "+";
      }
      return (+(
        Math.round(+arr[0] + "e" + sig + (+arr[1] + prec)) +
        "e-" +
        prec
      )).toFixed(prec);
    }
  };
  s = (prec ? toFixedFix(n, prec).toString() : "" + Math.round(n)).split(".");
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    s[1] += new Array(prec - s[1].length + 1).join("0");
  }
  return s.join(dec);
};

export const downloadPDFweb = (pdf, isBase64 = true) => {
  const linkSource = isBase64 ? `data:application/pdf;base64,${pdf}` : pdf;
  const downloadLink = document.createElement("a");
  const fileName = "vct_illustration.pdf";

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const abrirUrlExterno = (url) => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ code: "OPEN_EXTERNAL_URL", url })
    );
  } else {
    window.open(url, "_blank");
  }
};

export const downloadPDF = (pdf, isBase64 = true) => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ code: "DOWNLOAD_FILE", data: { file: pdf, isBase64 } })
    );
  } else {
    downloadPDFweb(pdf, isBase64);
  }
};

const patt = new RegExp(
  "^\\.*(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%.^*&+=])[a-zA-Z0-9@#*$%.^&+=]\\S*$"
);

export const isValidPassword = (password) =>
  patt.test(password) && password.length >= 8;

export const actualizarIdioma = async ({
  token,
  lenguaje,
  dispatch,
  history,
  t,
}) => {
  try {
    let headers = { token };
    let body = { lenguaje };
    let url = "/user/preferencia/actualizar/lenguaje";
    let response = await requestPostWhithHeaders(url, body, headers, dispatch);
    if (response && response.status === 0) {
      if (window.ReactNativeWebView) {
        window.localStorage.removeItem("token");
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            code: "LOGOUT_MESSAGE",
            data: {
              title: t("reducer.cambioIdioma"),
              message: t("reducer.cambiarIdiomaMsg"),
              button: t("membresiasPage.aceptar"),
            },
          })
        ); //logout
      } else {
        window.localStorage.removeItem("token");
        dispatch(
          Actions.showMessage({
            message: t("reducer.cambiarIdiomaMsg"),
            variant: "success",
          })
        );
        dispatch(Action.closeSesion(history));
      }
    }
  } catch (error) {}
};

export const getOsType = () => {
  const ua = navigator.userAgent;
  if (/android/i.test(ua)) {
    return OS_TYPES.ANDROID;
  } else if (
    /iPad|iPhone|iPod/.test(ua) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  ) {
    return OS_TYPES.IOS;
  }
  return OS_TYPES.OTRO;
};

export const isMobile = () => {
  const osType = getOsType();
  return osType === OS_TYPES.ANDROID || osType === OS_TYPES.IOS;
};

export const volverAtrasDespuesDe3ds = (history, factor = 2) => {
  const element = document.getElementById("count3ds");
  if (element) {
    let count3ds = parseInt(element.value || 0);
    if (count3ds > 0) {
      console.log("Go Current: ", count3ds);
      history.go(-2 * count3ds - factor);
    } else {
      console.log("GoBack: ");
      history.go(-2);
    }
  }
};

export const copiarTexto = async(texto) => {
  await navigator.clipboard.writeText(texto);
}